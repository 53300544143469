import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from 'src/app/shared/service/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { map, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import * as Notiflix from 'notiflix';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

// import { GrapesjsComponent } from "./grapesjs/grapesjs.component";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';


export interface resultados {
  name: string;
}
@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MatStepperModule, MatFormFieldModule,
    MatInputModule,
    MatChipsModule, MatIconModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent implements OnInit {
  infoForm: FormGroup;
  productInfo: any;
  formSubmitted = false;
  tiposDeRecursos: string[] = ['Video', 'Ebook', 'Documento'];
  // tipoRecursoSeleccionado: string = '';
  selectedFile: File | null = null;
  selectedRecursoFile: File | null = null;
  selectedProductId: number | null = null;  // Aquí almacenamos el ProductId
  selectedStep = 0;
  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild(MatStepper) stepper: MatStepper;
  crearPlantilla: boolean;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public resultados: any[] = [];

  public deseo: File | null = null; // Almacena el archivo
  public imagenUrl: string | ArrayBuffer | null = null; // Almacena la URL de la imagen
  public tipoRecursoSeleccionado: string = ''; // Almacena el tipo de recurso seleccionado
  public archivoSeleccionado: File | null = null; // Almacena el archivo seleccionado
  constructor(
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private ProductsService: ProductsService,
    private router: Router,
    private breakpointObserver: BreakpointObserver) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.infoForm = this.fb.group({
      micronicho: ['', Validators.required],
      propuesta: ['', Validators.required],
      GatilloM: ['', Validators.required],
      contador: ['', Validators.required],
      resultadoCurso: ['', Validators.required],
      deseo: ['', Validators.required],
      // problema: ['', Validators.required],
      // descripcion: ['', Validators.required],
      imagen: ['', Validators.required],
      tipoRecurso: ['', Validators.required],
      recurso: ['', Validators.required],
      somos: ['', Validators.required],
      FAQs: ['', Validators.required],
    });
  }

  resourceUrl: any;
  micronicho: any;
  problema: any;
  descripcion: any;
  propuesta: any;
  GatilloM: any;
  contador: any;
  resultadoCurso: any;
  somos: any;
  FAQs: any;
  ngOnInit(): void {
    // Capturamos el id del curso desde la URL
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.selectedProductId = Number(id);  // Asignamos el ProductId seleccionado
      }
    });
  }
  saveProduct() {

    const objetive = this.resultados.map((t: any) => { return t.name })
  }

  addResultado(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.resultados.push({ name: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  removeResultado(resultado: string): void {
    const index = this.resultados.indexOf(resultado);

    if (index >= 0) {
      this.resultados.splice(index, 1); // Elimina el resultado del arreglo
    }
  }

  onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.deseo = file;

      // Crear una URL de objeto para mostrar la imagen
      const reader = new FileReader();
      reader.onload = () => {
        this.imagenUrl = reader.result; // Almacena la URL de la imagen
      };
      reader.readAsDataURL(file); // Leer la imagen como URL de datos
    }
  }
  // onFileSelected(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     this.selectedFile = input.files[0];
  //     const file = input.files[0];
  //     this.infoForm.patchValue({ imagen: this.selectedFile });
  //     this.infoForm.get('imagen')?.updateValueAndValidity();
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('type', 'images');
  //     this.ProductsService.uploadFile2(formData)
  //       .subscribe(resolve => {
  //         if (resolve.FileUrl !== null) {
  //           this.imagenUrl = resolve.FileUrl;
  //         }
  //       });
  //   }
  // }
  // onRecursoFileSelected(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     this.selectedRecursoFile = input.files[0];
  //     this.infoForm.patchValue({ recurso: this.selectedRecursoFile });
  //     this.infoForm.get('recurso')?.updateValueAndValidity();
  //     const file = input.files[0];
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('type', 'images');
  //     this.ProductsService.uploadFile2(formData)
  //       .subscribe(resolve => {
  //         if (resolve.FileUrl !== null) {
  //           this.resourceUrl = resolve.FileUrl;
  //         }
  //       });
  //   }
  // }
  // onTipoRecursoChange(): void {
  //   this.tipoRecursoSeleccionado = this.infoForm.get('tipoRecurso')?.value;
  //   this.infoForm.get('recurso')?.setValue('');
  //   this.infoForm.get('recurso')?.updateValueAndValidity();
  // }
  onTipoRecursoChange(event: Event) {
    this.tipoRecursoSeleccionado = (event.target as HTMLSelectElement).value;
    console.log(`Tipo de recurso seleccionado: ${this.tipoRecursoSeleccionado}`);
  }

  onFile(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.archivoSeleccionado = file; // Almacena el archivo
      console.log(`Archivo seleccionado: ${this.archivoSeleccionado.name}`);
    }
  }
  mostrarInputRecurso(): boolean {
    return this.tipoRecursoSeleccionado === 'Video' || this.tipoRecursoSeleccionado === 'Ebook' || this.tipoRecursoSeleccionado === 'Documento';
  }
  isFieldInvalid(field: string): boolean {
    const control = this.infoForm.get(field);
    return control?.invalid && control?.touched;
  }
  onSubmit(): void {
    if (this.infoForm.valid && this.selectedProductId) {
      this.formSubmitted = true;
      const bodies = [
        { Property: "Landing-Micronicho", Value: this.infoForm.value.micronicho, ProductId: this.selectedProductId },
        { Property: "Landing-Propuesta", Value: this.infoForm.value.propuesta, ProductId: this.selectedProductId },
        { Property: "Landing-GatilloM", Value: this.infoForm.value.GatilloM, ProductId: this.selectedProductId },
        { Property: "Landing-contador", Value: this.infoForm.value.contador, ProductId: this.selectedProductId },
        { Property: "Landing-resultadoCurso", Value: this.infoForm.value.resultadoCurso, ProductId: this.selectedProductId },
        { Property: "Landing-Deseo", Value: this.infoForm.value.deseo, ProductId: this.selectedProductId },
        { Property: "Landing-Problema", Value: this.infoForm.value.problema, ProductId: this.selectedProductId },
        { Property: "Landing-Descripcion", Value: this.infoForm.value.descripcion, ProductId: this.selectedProductId },
        // { Property: "Landing-Imagen", Value: this.imagenUrl, ProductId: this.selectedProductId },
        { Property: "Landing-Tipo", Value: this.infoForm.value.tipoRecurso, ProductId: this.selectedProductId },
        { Property: "Landing-Recurso", Value: this.resourceUrl, ProductId: this.selectedProductId },
        { Property: "Landing-somos", Value: this.infoForm.value.somos, ProductId: this.selectedProductId },
        { Property: "Landing-FAQS", Value: this.infoForm.value.FAQs, ProductId: this.selectedProductId },

      ];
      // Enviar la solicitud al backend con el ProductId
      this.ProductsService.enviarloanding(bodies).subscribe(
        (response) => {
          // console.log('Formulario enviado correctamente', response);
          Swal.fire({
            title: 'Éxito!',
            text: 'El formulario se ha enviado correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.router.navigate(['/products/courses']);  // Redirige a la URL deseada
          });
        },
        (error) => {
          console.error('Error al enviar el formulario', error);
          Swal.fire({
            title: 'Error!',
            text: 'Ocurrió un problema al enviar el formulario.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      );
    } else {
      // console.log('El formulario o el ProductId es inválido');
      this.formSubmitted = false;
    }
  }
  goToNextStep(e: any) {
    this.stepper.next();
    if (e == 1 || e == 2) {
      Notiflix.Notify.success('Plantilla seleccionada');
      this.crearPlantilla = false;
    } else {
      this.crearPlantilla = true;
    }
  }
}
// onSubmit(): void {
//   if (this.infoForm.valid) {
//     const formData = new FormData();
//     formData.append('micronicho', this.infoForm.get('micronicho')?.value);
//     formData.append('deseo', this.infoForm.get('deseo')?.value);
//     formData.append('problema', this.infoForm.get('problema')?.value);
//     formData.append('descripcion', this.infoForm.get('descripcion')?.value);
//     formData.append('tipoRecurso', this.infoForm.get('tipoRecurso')?.value);
//     if (this.selectedFile) {
//       formData.append('imagen', this.selectedFile);
//     }
//     if (this.infoForm.get('tipoRecurso')?.value === 'Video') {
//       formData.append('recurso', this.infoForm.get('recurso')?.value);
//     } else {
//       const recursoInput = this.infoForm.get('recurso')?.value as File;
//       formData.append('recurso', recursoInput);
//     }
//     console.log('Formulario enviado', formData);
//   } else {
//     console.log('El formulario es inválido');
//   }
// }