import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs';
import { ProductsService } from 'src/app/shared/service/product.service';
import { productDB } from 'src/app/shared/tables/product-list';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  //public product_list = []
  //this.product_list = productDB.product;

  public url: string = '';
  public search: string = '';
  public product_list: any[] = [];
  public totalProducts: number;
  public showFilter = false;
  public productId: number;
  public deleteIsSuccess = false;
  active: boolean;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private productService: ProductsService
  ) { }

  ngOnInit() {
    const storedSearch = localStorage.getItem('searchValue');
    if (storedSearch) {
      this.search = storedSearch;
      this.showFilter = true;
    }
    this.loadData();
  }
  updateSearchValue(newValue: string) {
    this.search = newValue;
    if (this.search.trim()) {
      localStorage.setItem('searchValue', this.search);
    }
    this.loadData();
  }
  clearSearch() {
    this.search = '';
    localStorage.removeItem('searchValue');
    this.loadData();
  }
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }
  setModalContent(content: any, productId: number, status: boolean): void {
    this.productId = productId;
    this.active = status;
    this.modalService.open(content, this.modalConfig);
  }

  loadData() {
    const url = this.router.url.split("/").pop();
    if (url == 'courses') {

      const user = JSON.parse(localStorage['currentUser']);

      this.productService
        .getCourses2(this.search, user.id)
        .subscribe(data => {
          this.product_list = data;
          this.totalProducts = this.product_list.length;
        })
    } else {
      this.productService
        .getCapacitations()
        .subscribe(data => {
          this.product_list = data;
          this.totalProducts = this.product_list.length;
        })
    }
  }

}
