// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: "https://api-qa.learnify.com.mx/api",
  apikey_grop: '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'
};


export const googleSearch = {
  key: 'AIzaSyCN85pmfKDFaTDZ9uUqAsfBPgwDYzdljEU',
  googleContext: '81d42b2866e734b6a',
  googleLink :'https://www.googleapis.com/customsearch/v1'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
