<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/designer.jpg" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">Luis Wong</h5>
                        <span>luis&#64;stackcode.io</span>
                        <div class="social">
                            <div class="form-group btn-showcase">
                                <button class="btn social-btn btn-fb d-inline-block">
                                    <i class="fa fa-facebook"></i></button>
                                <button class="btn social-btn btn-twitter d-inline-block"><i
                                        class="fa fa-google"></i></button>
                                <button class="btn social-btn btn-google d-inline-block me-0"><i
                                        class="fa fa-twitter"></i></button>
                            </div>
                        </div>
                    </div> 
                    <hr>
                    <div class="project-status">
                        <h5 class="f-w-600">Employee Status</h5>
                        <div class="media">
                            <div class="media-body">
                                <h6>Performance<span class="pull-right">80%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-primary" role="progressbar" style="width: 90%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Overtime <span class="pull-right">60%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Leaves taken<span class="pull-right">70%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 70%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink><i class="me-2" data-feather="user"></i>Generales</a>
                          <ng-template ngbNavContent>
                            <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                            aria-labelledby="top-profile-tab">
                            <br>
                            <h5 class="f-w-600"> Profile</h5>
                            <div class="table-responsive profile-table">
                                <table class="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Nombre:</td>
                                            <td>Johan</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>johndeo&#64;gmail.com</td>
                                        </tr>
                                        <tr>
                                            <td>Telefono:</td>
                                            <td>2124821463</td>
                                        </tr>
                                        <tr>
                                            <td>Mensaje:</td>
                                            <td>Male</td>
                                        </tr>
                                        <tr>
                                            <td>Ubicaciòn:</td>
                                            <td>USA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink class="d-flex align-items-center"><i data-feather="settings" class="me-2"></i>Educaciòn</a>
                          <ng-template ngbNavContent>
                            <div class="account-setting">
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3"></div>
                                        <label for="ultimoGradoEstudios" class="form-label">Grado de estudios</label>
                                        <select class="form-select" id="ultimoGradoEstudios" input [(ngModel)]="ultimoGradoEstudios" #ultimoGradoEstudiosInput="ngModel" name="ultimoGradoEstudios">
                                            
                                             <option value="primaria">Primaria</option>
                                             <option value="secundaria">Secundaria</option>
                                             <option value="preparatoria">Preparatoria</option>
                                             <option value="universidad">Universidad</option>
                                             <option value="maestria">Maestría</option>
                                             <option value="doctorado">Doctorado</option>
                                         </select>
                                         <div *ngIf="!ultimoGradoEstudios && ultimoGradoEstudiosInput.touched" class="text-danger">
                                             Este campo es obligatorio.
                                         </div>
                                     </div>
                                     <div class="mb-3">
                                         <label for="carrera" class="form-label">Especialidad</label>
                                         <input type="text" class="form-control" id="carrera" placeholder="Carrera"
                                                [(ngModel)]="carrera" name="carrera"
                                                required 
                                                #carreraInput="ngModel">
                                         <div *ngIf="carreraInput.invalid && carreraInput.touched" class="text-danger">
                                             Este campo es obligatorio.
                                         </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="escuela" class="form-label">Escuela</label>
                                            <input type="text" class="form-control" id="escuela" placeholder="Escuela"
                                                   [(ngModel)]="escuela" name="escuela"
                                                   required 
                                                   #escuelaInput="ngModel">
                                            <div *ngIf="escuelaInput.invalid && escuelaInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                </div>
                                <div class="mb-3">
                                    <label for="aboutMe" class="form-label">Biografía</label>
                                    <textarea class="form-control" id="aboutMe" rows="3" maxlength="300"
                                              placeholder="Máximo 300 caracteres"
                                              [(ngModel)]="aboutMe" 
                                              name="aboutMe" 
                                              required 
                                              #aboutMeInput="ngModel"
                                              (input)="checkCharacterLimit()"></textarea>
                                    <div *ngIf="aboutMeInput.invalid && aboutMeInput.touched" class="text-danger">
                                        Este campo es obligatorio.
                                    </div>
                                    <div *ngIf="aboutMe?.length >= 300" class="text-danger">
                                        Has alcanzado el límite de 300 caracteres.
                                    </div>
                                        
                                       
                                    </div>
                                </div>
                              
                            </div>
                            
                          </ng-template>
                        </li>
                         <!-- Formulario Tab -->
                        <li [ngbNavItem]="3">
                            <a ngbNavLink class="d-flex justify-content-start">
                                <i data-feather="edit" class="me-2"></i>Experiencia Laboral
                            </a>
                            <ng-template ngbNavContent>
                                <div class="account-setting">
                                    <br>
                                    <br>
                                    <h5 class="f-w-600">Formulario</h5>
                                    <form (ngSubmit)="guardarAntecedente()">
                                        <form (ngSubmit)="enviarDatosPerfil()" #updateProfile="ngForm"></form>
                                      <div class="mb-3">
                                            <label for="ultimoGradoEstudios" class="form-label">Grado de estudios</label>
                                           <select class="form-select" id="ultimoGradoEstudios" input [(ngModel)]="ultimoGradoEstudios" #ultimoGradoEstudiosInput="ngModel" name="ultimoGradoEstudios">
                                               
                                                <option value="primaria">Primaria</option>
                                                <option value="secundaria">Secundaria</option>
                                                <option value="preparatoria">Preparatoria</option>
                                                <option value="universidad">Universidad</option>
                                                <option value="maestria">Maestría</option>
                                                <option value="doctorado">Doctorado</option>
                                            </select>
                                            <div *ngIf="!ultimoGradoEstudios && ultimoGradoEstudiosInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="carrera" class="form-label">Carrera</label>
                                            <input type="text" class="form-control" id="carrera" placeholder="Carrera"
                                                   [(ngModel)]="carrera" name="carrera"
                                                   required 
                                                   #carreraInput="ngModel">
                                            <div *ngIf="carreraInput.invalid && carreraInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="escuela" class="form-label">Escuela</label>
                                            <input type="text" class="form-control" id="escuela" placeholder="Escuela"
                                                   [(ngModel)]="escuela" name="escuela"
                                                   required 
                                                   #escuelaInput="ngModel">
                                            <div *ngIf="escuelaInput.invalid && escuelaInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                                           <!--</div>
                                        <div class="mb-3">
                                            <label for="expertise" class="form-label">Expertise</label>
                                            <input type="text" class="form-control" id="expertise" placeholder="Expertise"
                                                   [(ngModel)]="expertise" name="expertise"
                                                   required 
                                                   #expertiseInput="ngModel">
                                            <div *ngIf="expertiseInput.invalid && expertiseInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>--> 
                          
                                        </div>
                                        <div class="mb-3">
                                            <label for="aboutMe" class="form-label">Biografía</label>
                                            <textarea class="form-control" id="aboutMe" rows="3" maxlength="300"
                                                      placeholder="Máximo 300 caracteres"
                                                      [(ngModel)]="aboutMe" 
                                                      name="aboutMe" 
                                                      required 
                                                      #aboutMeInput="ngModel"
                                                      (input)="checkCharacterLimit()"></textarea>
                                            <div *ngIf="aboutMeInput.invalid && aboutMeInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                            <div *ngIf="aboutMe?.length >= 300" class="text-danger">
                                                Has alcanzado el límite de 300 caracteres.
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="fbUrl" class="form-label">Facebook</label>
                                            <input type="url" class="form-control" id="fbUrl" placeholder="https://facebook.com/tuPerfil"
                                                   [(ngModel)]="fbUrl" name="fbUrl"required #fbUrlInput="ngModel">
                                                   <div *ngIf="fbUrlInput.invalid && fbUrlInput.touched" class="text-danger">
                                                    Este campo es obligatorio.
                                                  </div>    
                                        </div>
                                        <div class="mb-3">
                                            <label for="xUrl" class="form-label">X (Twitter)</label>
                                            <input type="url" class="form-control" id="xUrl" placeholder="https://twitter.com/tuPerfil"
                                                   [(ngModel)]="xUrl" name="xUrl"required #xUrlInput="ngModel">
                                                   <div *ngIf="xUrlInput.invalid && xUrlInput.touched" class="text-danger">
                                                    Este campo es obligatorio.
                                                  </div>  
                                        </div>
                                        <div class="mb-3">
                                            <label for="lnUrl" class="form-label">LinkedIn</label>
                                            <input type="url" class="form-control" id="lnUrl" placeholder="https://linkedin.com/in/tuPerfil"
                                                   [(ngModel)]="lnUrl" name="lnUrl"required #lnUrlInput="ngModel">
                                                   <div *ngIf="lnUrlInput.invalid && lnUrlInput.touched" class="text-danger">
                                                    Este campo es obligatorio.
                                                  </div>  
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: 1rem; font-weight: bold;">Experiencia</label>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Empresa</th>
                                                        <th scope="col">Cargo</th>
                                                        <th scope="col">Periodo de Inicio</th>
                                                        <th scope="col">Periodo de Fin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let antecedente of antecedentes">
                                                        <td>{{ antecedente.empresa }}</td>
                                                        <td>{{ antecedente.cargo }}</td>
                                                        <td>{{ antecedente.periodoInicio }}</td>
                                                        <td>{{ antecedente.periodoFin }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                         <div class="button-container" style="display: flex; gap: 20px;">
                                            <button type="button" class="btn btn-primary" (click)="mostrarFormulario(dialogTemplate)">Agregar Antecedentes</button> 
                                           <form (ngSubmit)="enviarDatosPerfil()" #updateProfile="ngForm">
                                           <button type="submit" class="btn btn-success" [disabled]="!updateProfile.valid">Enviar</button>
                                          </form>
                                        </div>
                                        <div *ngIf="formSubmitted" class="alert alert-success mt-3">¡Formulario enviado correctamente!</div>
                                        </div>
                                        <ng-template #dialogTemplate>
                                            <div class="form-container">
                                            <h6 class="f-w-600">Nuevo Antecedente</h6>
                                            <div class="mb-3">
                                                <label for="empresa" class="form-label">Empresa</label>
                                                <input type="text" class="form-control" id="empresa" placeholder="Empresa"
                                                       [(ngModel)]="nuevoAntecedente.empresa" name="empresa" required
                                                       #empresaInput="ngModel">
                                                <div *ngIf="empresaInput.invalid && empresaInput.touched" class="text-danger">
                                                    Este campo es obligatorio.
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label for="cargo" class="form-label">Cargo</label>
                                                <input type="text" class="form-control" id="cargo" placeholder="Cargo"
                                                       [(ngModel)]="nuevoAntecedente.cargo" name="cargo" required
                                                       #cargoInput="ngModel">
                                                <div *ngIf="cargoInput.invalid && cargoInput.touched" class="text-danger">
                                                    Este campo es obligatorio.
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label for="periodoInicio" class="form-label">Periodo de Inicio</label>
                                                <input type="date" class="form-control" id="periodoInicio"
                                                       [(ngModel)]="nuevoAntecedente.periodoInicio" name="periodoInicio" required
                                                       #periodoInicioInput="ngModel">
                                                <div *ngIf="periodoInicioInput.invalid && periodoInicioInput.touched" class="text-danger">
                                                    Este campo es obligatorio.
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <label for="periodoFin" class="form-label">Periodo de Fin</label>
                                                <input type="date" class="form-control" id="periodoFin"
                                                       [(ngModel)]="nuevoAntecedente.periodoFin" name="periodoFin">
                                            </div>
                                            <style>
                                              /* .modal-footer { */
                                                /* display: flex; */
                                                /* justify-content: flex-end; Alinea los botones al final (a la derecha) */
                                                /* gap: 230px; Espacio automático entre botones */
                                              /* } */
                                            </style>
                                            <!-- <div class="modal-footer"> -->
                                              <div class="text-center">
                                            <button type="button" class="btn btn-success" (click)="guardarAntecedente()">Guardar</button>
                                            <!-- <button type="button" class="btn btn-secondary" (click)="dialog.closeAll()">Cerrar</button> -->
                                            
                                        </div><br>  
                                    </div>               
                                        </ng-template>
                                    </form>
                                </div>
                            </ng-template>
                          </li>
  <div [ngbNavOutlet]="nav" class="mt-3"></div> 

                    <!-- <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile</ng-template>
                            <ng-template ngbTabContent>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                               
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset> -->
       <!--        </div>
            </div>
        </div>
    </div>
</div>--> 
<!-- Container-fluid Ends-->