import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { GptFormComponent } from './components/grop/gpt-form/gpt-form.component';
//import { StudentsComponent } from './components/estudiantes/students.component';


//No se encuentra el módulo "./components/estudiantes/students.component" ni sus declaraciones de tipos correspondientes.ts(2307)

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  }
  
  /*{ path: 'students', 
    component: StudentsComponent, 

 },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
