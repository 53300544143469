<!-- Container-fluid starts-->


<div class="row">
    <!-- <div class="col-md-4 mb-4">
        <div class="card shadow">
            <div class="card-body">

            </div>
        </div>

        <div class="card shadow">
            <div class="card-body">
                <h4 class="title-file">Cargar manual de producto</h4>
                <ngx-dropzone (change)="onSelectFiles($event)" [multiple]="false">
                    <ngx-dropzone-label class="label-file"> Arrastra un pdf aquí o haz clic para seleccionar una imagen
                        desde tu computadora.</ngx-dropzone-label>


                    <ngx-dropzone-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name.substr(1,10) }}... / ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
                <section *ngIf="responseFile" style="margin-top: 10px;">
                    <a [href]="responseFile" target="_black" style="color:#33bd38">archivo cargado <i
                            class="fa fa-check" aria-hidden="true"></i></a>
                </section>

            </div>
        </div>
    </div> -->
    <div class="col-12">
        <div class="card shadow">

            <div class="card-body">
                <div class="container"><!--[ngClass]="{'porCompletar': !completadoEstudioMercado}"-->
                    <!-- <mat-horizontal-stepper  class="example-stepper" [linear]="" #stepper (selectionChange)="stepperChange($event)" >
                            <mat-step [stepControl]="" label="Mercado" >


                              <div class="mt-4 bloque-item" >
                                <span class="bloque-title">Estudio de Mercado</span>
                                <div class="row ">
                                    <div class="col-md-4 columnas">
                                        <h4>¿A quién quieres buscar?</h4>
                                        <select class="form-select" aria-label="Default select example" (change)="selectorOption($event)">
                                            <option value="Empresas">Empresas</option>
                                            <option value="Couches">Coaches</option>
                                            <option value="Marcas personales">Marcas personales</option>
                                            <option value="Influencers">Influencers</option>
                                            <option value="Artistas">Artistas</option>
                                        </select>

                                        <h4 style="margin-top: 1rem;">¿Qué tema quieres buscar?</h4>
                                        <div class="input-group flex-nowrap">
                                            <input type="text" class="form-control inputTema" placeholder="Ejemplo: Neuroventas" aria-label="Username" aria-describedby="addon-wrapping" (input)="updateInputValue($event, 'tema')">
                                        </div>

                                        <h4 style="margin-top: 1rem;">Ubicación</h4>
                                        <div class="input-group flex-nowrap">
                                            <input type="text" class="form-control inputTema" placeholder="Ejemplo: México" aria-label="Username" aria-describedby="addon-wrapping" (input)="updateInputValue($event, 'ubicacion')">
                                        </div>
                                    </div>

                                    <div class="col-md-4 columnas" >

                                        <div class="textarea-container" style="height: 100%;">
                                            <textarea id="promptChatGPT" class="form-control textArea" [(value)]="promptTxt"
                                                rows="4" placeholder="Prompt" readonly style="height: 100%;"></textarea>
                                            <button style="padding: 3px; "
                                                class="btn btn-outline-secondary copy-button" type="button"
                                                (click)="copyToClipboard()">
                                                <i style="margin: 0;" class="fa fa-copy"></i>
                                            </button>
                                            <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
                                        </div>



                                    </div>
                                </div>
                                <form [formGroup]="responseGPT" (ngSubmit)="guardarPrompt()">
                                    <div class="row">
                                        <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                            <div class="form-group ">
                                            <textarea id="promptResChat" class="form-control" style="margin-top: 2rem;" rows="4" placeholder="Prompt"
                                                formControlName="promptResChat"
                                                [ngClass]="{'error' :  responseGPT.get('promptResChat').invalid  && banderaGuardarPrompt }"></textarea>
                                                <span>2.- Pega la respuesta de chatGPT</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div style="justify-content: end; margin-bottom: 1rem;">
                                            <button mat-button matStepperNext  [disabled]=" responseGPT.get('promptResChat').invalid " (click)="notification()" type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </mat-step>
                            <mat-step [stepControl]="" label="Competencía" >

                              <div class="bloque-item mt-4" >
                                <span class="bloque-title">Investigar Competencía</span>
                                <form [formGroup]="estudioMerca" (ngSubmit)="estudioDeMercado()">
                                    <div class="row">
                                        <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                            <div class="form-group">
                                                <label>1er Sitio Web</label>
                                                <input type="url" class="form-control" formControlName="website1"
                                                    placeholder="Copia la Url del sitio web de tu competencia"
                                                    [ngClass]="{'error' :  estudioMerca.get('website1').invalid  && banderaEstudioMerca }">
                                            </div>
                                            <div class="form-group">

                                                <div class="textarea-container">
                                                    <textarea id="promptChatGPT2" class="form-control" [(value)]="promptTxtWeb"
                                                        rows="4" placeholder="Prompt" readonly></textarea>
                                                    <button style="padding: 3px; "
                                                        class="btn btn-outline-secondary copy-button" type="button"
                                                        (click)="copyToClipboard2()">
                                                        <i style="margin: 0;" class="fa fa-copy"></i>
                                                    </button>
                                                </div>
                                                <span>1-. Copia esto y pegalo en <a href="https://chatgpt.com/" target="_blank">chatGPT</a></span>
                                            </div>
                                            <div class="row" style="justify-content: center; margin-bottom: 1rem;">
                                                <button type="submit" [disabled]="estudioMerca.get('website1').invalid"  class="btn btn-primary">Genera prompt con IA</button>
                                            </div>


                                        </div>
                                    </div>
                                </form>
                                <form [formGroup]="formGuardar" (ngSubmit)="enviarResChatGpt()">
                                    <div class="form-group">
                                        <textarea id="promptResChat" class="form-control" rows="4" placeholder="Prompt"
                                            formControlName="promptResChat"
                                            [ngClass]="{'error' :  formGuardar.get('promptResChat').invalid  && banderaEnvioPrompt }"></textarea>
                                        <span>2.- pega la respuesta de chatGPT</span>
                                    </div>
                                    <div class="form-group">
                                        <div style="justify-content: end; margin-bottom: 1rem;">
                                            <button (click)="notification()" type="submit" class="btn btn-primary" [disabled]="formGuardar.get('promptResChat').invalid" mat-button matStepperNext>Guardar Respuesta</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </mat-step>
                            <mat-step label="Avatar">
                                <div class="mt-4">
                                    <div class="bloque-item">
                                        <span class="bloque-title">Diseña tu Avatar</span>

                                        <div style="padding: 0 1rem 1rem 1rem;">
                                            <div class="row">
                                                <h4>CÓMO SACARLE EL MÁXIMO PROVECHO:</h4>
                                            </div>
                                            <div class="row">
                                                <p>Esta hoja de trabajo requiere tiempo y esfuerzo, así que a mover las neuronas 😅. Ten en cuenta que todo lo que escribirás en este documento será tomando en cuenta <strong>el punto de vista de tu cliente</strong>. NO EL TUYO. **Ponte en sus zapatos** (literalmente).<br><br>

                                                    Ten en cuenta que no puedes atender a todo el mundo. Sin especializarse nos volvemos generalistas, no podremos diferenciarnos y convertir nuestro negocio en una experiencia única.<br><br>

                                                    Por último, recuerda que este ejercicio toma su tiempo, pero es clave para ayudarnos a desarrollar una identidad, un mensaje de marketing poderoso y una oferta diferente a la del resto.<br><br>

                                                  <strong>Busca que tu producto tenga un factor WOW, alguna innovación que lo haga único y MUY superior a tus competidores o a lo que ya hay en el mercado.</strong></p>
                                            </div>
                                        </div>

                                        <p style="margin: 0;">1.- Escribe palabras clave que describan todo lo relacionado contigo y con tu producto.</p>
                                        <mat-form-field class="example-chip-list" style="width: 100%;">
                                            <mat-label>Palabras clave</mat-label>
                                            <mat-chip-list #chipList aria-label="palabras clave">
                                                <mat-chip *ngFor="let nombreCurso of nombresCurso" [selectable]="selectable"
                                                        [removable]="removable" (removed)="remove(nombreCurso)">
                                                {{nombreCurso.name}}
                                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip>
                                                <input placeholder="Palabras clave"
                                                        [matChipInputFor]="chipList"
                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                        [matChipInputAddOnBlur]="addOnBlur"
                                                        (matChipInputTokenEnd)="add($event)">
                                            </mat-chip-list>
                                        </mat-form-field>


                                        <div class="row-md-6" style="margin-bottom: 1rem;">
                                            <p>2.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
                                            <div style="position: relative;">
                                                <textarea id="promptChatGPTAvatar" class="form-control textArea" [(value)]="promptPalabrasClave"
                                                    rows="4" placeholder="Prompt" readonly style="height: 100%;"></textarea>
                                                <button style="padding: 3px; position: absolute; top: 0; right: 0;"
                                                    class="btn btn-outline-secondary copy-button" type="button"
                                                    (click)="copyToClipboardAvatar()">
                                                    <i style="margin: 0;" class="fa fa-copy"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <form [formGroup]="fromAvatarNom" (ngSubmit)="guardarPromptAvatar()">
                                            <div class="row">
                                                <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                    <div class="form-group ">
                                                    <span>3.- Pega la respuesta de chatGPT</span>
                                                    <textarea id="resAvatarNom" class="form-control" rows="4" placeholder="Prompt"
                                                        formControlName="resAvatarNom"
                                                        [ngClass]="{'error' :  fromAvatarNom.get('resAvatarNom').invalid  && banderaAvatarNombre }"></textarea>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div style="justify-content: end; margin-bottom: 1rem;">
                                                    <button   [disabled]=" fromAvatarNom.get('resAvatarNom').invalid " (click)="notification()" type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                                </div>
                                            </div>
                                        </form>

                                        <hr>
                                        <div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h4 style="margin-top: 1rem;">¿Quién eres?</h4>
                                                    <div class="input-group flex-nowrap">
                                                        <input type="text" class="form-control inputTema" placeholder="Ejemplo: Ingeniero en software" aria-label="Username" aria-describedby="addon-wrapping" (input)="updatePromptAvatarConNombreCurso($event,'profession')">
                                                    </div>

                                                    <h4 style="margin-top: 1rem;">¿Qué quieres enseñarles?</h4>
                                                    <div class="input-group flex-nowrap">
                                                        <input type="text" class="form-control inputTema" placeholder="Ejemplo: quiero enseñarle a las personas a programar" aria-label="Username" aria-describedby="addon-wrapping" (input)="updatePromptAvatarConNombreCurso($event,'teaching')">
                                                    </div>

                                                    <h4 style="margin-top: 1rem;">¿Cómo se llama tu curso?</h4>
                                                    <div class="input-group flex-nowrap">
                                                        <input type="text" class="form-control inputTema" placeholder="Ejemplo: Si quieres programar, callate y teclea" aria-label="Username" aria-describedby="addon-wrapping" (input)="updatePromptAvatarConNombreCurso($event,'courseName')">
                                                    </div>

                                                </div>
                                                <div class="col-md-6" >
                                                    <textarea id="promptChatGPTAvatarFinal" class="form-control textArea" [(value)]="promptAvatarConNombreCurso"
                                                    rows="4" placeholder="Prompt" readonly style="height: 100%;"></textarea>
                                                    <button style="padding: 3px; "
                                                        class="btn btn-outline-secondary copy-button" type="button"
                                                        (click)="copyToClipboardAvatarFin()">
                                                        <i style="margin: 0;" class="fa fa-copy"></i>
                                                    </button>

                                                </div>

                                            </div>
                                            <div style="display: flex; justify-content: end;">
                                                <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
                                            </div>
                                        </div>
                                        <form [formGroup]="formAvatarFinal" (ngSubmit)="guardarPrompt()">
                                            <div class="row">
                                                <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                    <div class="form-group ">
                                                    <textarea id="resPromptAvatar" class="form-control" style="margin-top: 2rem;" rows="4" placeholder="Prompt"
                                                        formControlName="resPromptAvatar"
                                                        [ngClass]="{'error' :  formAvatarFinal.get('resPromptAvatar').invalid  && banderaAvatarFinal }"></textarea>
                                                        <span>2.- Pega la respuesta de chatGPT</span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div style="justify-content: end; margin-bottom: 1rem;">
                                                    <button mat-button matStepperNext  [disabled]=" formAvatarFinal.get('resPromptAvatar').invalid " (click)="notification()" type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                                </div>
                                            </div>
                                        </form>



                                    </div>
                                </div>
                            </mat-step>
                            <mat-step label="Estructura">
                                <div class="mt-4">
                                    <div class="bloque-item">
                                        <span class="bloque-title">Estructura del curso</span>

                                        <div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h4 style="margin-top: 1rem;">¿Quién eres?</h4>
                                                    <div class="input-group flex-nowrap">
                                                        <input type="text" class="form-control inputTema" placeholder="Ejemplo: Ingeniero en software" aria-label="Username" aria-describedby="addon-wrapping" (input)="updateInputValueAvatar($event)">
                                                    </div>
                                                    <h4 style="margin-top: 1rem;">¿Qué quieres enseñarles?</h4>
                                                    <div class="input-group flex-nowrap">
                                                        <input type="text" class="form-control inputTema" placeholder="Ejemplo: quiero enseñarle a las personas a programar" aria-label="Username" aria-describedby="addon-wrapping" (input)="updateInputValueAvatarEn($event)">
                                                    </div>

                                                </div>
                                                <div class="col-md-6" >
                                                    <textarea id="promptChatGPTAvatar" class="form-control textArea" [(value)]="promptAvatar"
                                                    rows="4" placeholder="Prompt" readonly style="height: 100%;"></textarea>
                                                    <button style="padding: 3px; "
                                                        class="btn btn-outline-secondary copy-button" type="button"
                                                        (click)="copyToClipboardAvatar()">
                                                        <i style="margin: 0;" class="fa fa-copy"></i>
                                                    </button>

                                                </div>

                                            </div>
                                            <div style="display: flex; justify-content: end;">
                                                <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
                                            </div>
                                        </div>
                                        <form [formGroup]="formProducto" (ngSubmit)="guradarPromptProducto()">
                                            <div class="row">
                                                <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                    <div class="form-group ">
                                                    <textarea id="resPromptProducto" class="form-control" style="margin-top: 2rem;" rows="4" placeholder="Prompt"
                                                        formControlName="resPromptProducto"
                                                        [ngClass]="{'error' :  formProducto.get('resPromptProducto').invalid  && banderaGuardarPrompt }"></textarea>
                                                        <span>2.- Pega la respuesta de chatGPT</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div style="justify-content: end; margin-bottom: 1rem;">
                                                    <button mat-button matStepperNext  [disabled]=" formProducto.get('resPromptProducto').invalid " (click)="notification()" type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </mat-step>


                            <mat-step label="Contenido">
                                <div class="mt-4" >
                                    <form [formGroup]="courseForm" (ngSubmit)="onSubmit()" class="needs-validation row">
                                        <div class="bloque-item">
                                            <span class="bloque-title">Datos generales</span>
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="title">Título</label>
                                                                <input type="text" class="form-control" (keyup)="generateSlug($event)"
                                                                    [ngClass]="{'error' : submitted && courseForm.controls.Title.hasError('required') }"
                                                                    formControlName="Title" id="title" placeholder="Titulo">
                                                            </div>
                                                        </div>


                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Categoría:</label>
                                                                <select formControlName="category_id" name="category" class="custom-select form-select"
                                                                    required="">
                                                                    <option value="" selected disabled>Selecciona</option>
                                                                    <option *ngFor="let cat of categories" [value]="cat.id">{{
                                                                        cat.ValueAlpha }}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="title">Precio</label>
                                                                <input type="text" class="form-control" formControlName="UnitPrice" id="title"
                                                                    [ngClass]="{'error' : submitted && courseForm.controls.UnitPrice.hasError('required') }"
                                                                    placeholder="Precio">
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="bloque-item">
                                            <span class="bloque-title">Información del curso</span>
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Nivel: </label>
                                                        <select [formControlName]="'level_id'" name="level" class="custom-select form-select" required="">
                                                            <option value="" selected disabled>Selecciona</option>
                                                            <option *ngFor="let lvl of levelList" [value]="lvl.id">{{
                                                                lvl.ValueAlpha }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="title">Recursos descargables</label>
                                                        <input type="text" class="form-control" formControlName="TotalHours" id="title"
                                                            [ngClass]="{'error' : submitted && courseForm.controls.TotalHours.hasError('required') }"
                                                            placeholder="Recursos descargables">
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Descripción</label>
                                                        <textarea class="form-control" formControlName="Description" id="description" rows="3"
                                                            placeholder="Ingresa una descripción del curso"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Lecturas</label>
                                                        <textarea class="form-control" formControlName="Introduction" id="description" rows="3"
                                                            placeholder="Ingresa las Lecutras del curso"></textarea>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-md-6">

                                                    <div class="form-group">
                                                        <label for="description">Qué Aprenderas</label>
                                                        <textarea class="form-control" formControlName="Objective" id="description" rows="3"
                                                            placeholder="Ingresa lo que se aprendera en el curso"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">

                                                    <div class="form-group">
                                                        <label for="description">Dirigido a</label>
                                                        <textarea class="form-control" formControlName="TargetMarket" id="description" rows="3"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Certificados</label>
                                                        <textarea class="form-control" formControlName="certificados" id="description" rows="3"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="bloque-item">
                                            <span class="bloque-title">Módulos del curso</span>
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Título</label>
                                                                <input class="form-control" id="validationCustom05" type="text"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.Name" name="name">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0"> Número de
                                                                    temas</label>
                                                                <input class="form-control" id="validationCustom05" type="number"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.numTopics" name="topics">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Porcentaje</label>
                                                                <input class="form-control" id="validationCustom05" type="number"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.Percentage" name="percent">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Duración</label>
                                                                <input class="form-control" id="validationCustom05" type="text"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.courseDuration" name="duration">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <label>Cargar video</label>
                                                    <ngx-dropzone (change)="onSelectVideo($event)" [multiple]="false">
                                                        <ngx-dropzone-label *ngIf="!viewLoaderVideo && module.VideoUrl === ''" class="label-file"> Arrastra aquí
                                                            el video del
                                                            módulo.</ngx-dropzone-label>
                                                        <div *ngIf="viewLoaderVideo" class="spinner-border text-danger" role="status">
                                                            <span class="sr-only">Cargando...</span>
                                                        </div>
                                                        <ngx-dropzone-label *ngIf="!viewLoaderVideo && module.VideoUrl !== ''" class="label-file">
                                                            <i class="fa fa-check text-success"></i>
                                                            Video cargado correctamente.
                                                        </ngx-dropzone-label>
                                                    </ngx-dropzone>
                                                </div>
                                                <div class="col-12 d-flex justify-content-end py-2">
                                                    <button [disabled]="module.VideoUrl === ''" class="btn btn-primary" (click)="addModule();">Agregar
                                                        módulo</button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <table *ngIf="modules.length !== 0" class="w-100">
                                                        <tr>
                                                            <th>Eliminar</th>
                                                            <th>Título</th>
                                                            <th>Número de temas</th>
                                                            <th>Porcentaje</th>
                                                            <th>Duración</th>
                                                        </tr>
                                                        <tr *ngFor="let module of modules">
                                                            <td>
                                                                <button class="btn btn-sm btn-primary"><i class="fa fa-trash mr-0 text-white"></i></button>
                                                            </td>
                                                            <td><b>{{ module.Name }}</b></td>
                                                            <td>{{ module.numTopics }}</td>
                                                            <td>{{ module.Percentage }}</td>
                                                            <td>{{ module.courseDuration }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <button type="submit" class="btn btn-primary" >Crear curso</button>
                            </mat-step>
                            <mat-step label="Video">
                                <div class="mt-4">
                                    <div class="bloque-item">
                                        <span class="bloque-title">Grabacion de OBS</span>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step label="Ficha">
                                <div class="mt-4">
                                    <div class="bloque-item">
                                        <span class="bloque-title">Ficha del curso</span>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step label="Resumen">
                                <div class="mt-4">
                                    <div class="bloque-item">
                                        <span class="bloque-title">Confirmación</span>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper> -->

                    <mat-stepper class="example-stepper" [orientation]="(stepperOrientation | async)!" [linear]="true"
                        #stepper>

                        <mat-step [stepControl]="firstFormGroup">

                            <div>
                                <cdk-accordion class="example-accordion" style="max-width: 100%;">
                                    <cdk-accordion-item #accordionItem1="cdkAccordionItem"
                                        class="example-accordion-item" role="button" tabindex="0"
                                        id="accordion-header-1" [expanded]="true"
                                        [attr.aria-expanded]="accordionItem1.expanded" aria-controls="accordion-body-1">
                                        <div class="example-accordion-item-header fontArial"
                                            (click)="accordionItem1.toggle()">
                                            Estudio de Mercado
                                            <span class="example-accordion-item-description">
                                                Click para {{ accordionItem1.expanded ? 'cerrar' : 'abrir' }}
                                            </span>
                                        </div>
                                        <div class="example-accordion-item-body" role="region"
                                            [style.display]="accordionItem1.expanded ? '' : 'none'"
                                            id="accordion-body-1" aria-labelledby="accordion-header-1">
                                            <div class="mt-4 bloque-item">
                                                <span class="bloque-title fontArial">Estudio de Mercado</span>

                                                <mat-tab-group #tabGroup>
                                                    <mat-tab label="Introducir datos">
                                                        <form [formGroup]="firstFormGroup"
                                                            (ngSubmit)="searchIAmercado()">
                                                            <ng-template matStepLabel
                                                                class="fontArial">Mercado</ng-template>
                                                            <div class="row" style="margin: 1rem;">
                                                                <div class="col-md-6 ">
                                                                    <!--columna40 class="col-md-4 columnas"-->
                                                                    <!-- <span >1.- Contesta las siguientes preguntas</span> -->
                                                                    <!-- <h4>¿Qué perfil desea buscar?</h4> -->

                                                                    <mat-form-field appearance="outline"
                                                                        class="full-width">
                                                                        <mat-label>¿Qué perfil desea buscar?</mat-label>
                                                                        <select matNativeControl
                                                                            formControlName="select" required
                                                                            (change)="selectorOption($event)">
                                                                            <option value="Empresas">Empresas</option>
                                                                            <option value="Couches">Coaches</option>
                                                                            <option value="Marcas personales">Marcas
                                                                                personales</option>
                                                                            <option value="Influencers">Influencers
                                                                            </option>
                                                                            <option value="Artistas">Artistas</option>
                                                                        </select>
                                                                    </mat-form-field>

                                                                    <!-- <h4 style="margin-top: 1rem;">¿Cuál tema desea buscar?</h4> -->



                                                                    <mat-form-field appearance="outline"
                                                                        class="full-width">
                                                                        <mat-label>¿Cuál tema desea buscar?</mat-label>
                                                                        <input matInput type="text"
                                                                            formControlName="tema" required
                                                                            aria-describedby="addon-wrapping"
                                                                            (input)="updateInputValue($event, 'tema')">
                                                                    </mat-form-field>

                                                                    <!-- <h4 style="margin-top: 1rem;">Ubicación</h4> -->

                                                                    <mat-form-field appearance="outline"
                                                                        class="full-width">
                                                                        <mat-label>Ubicación</mat-label>
                                                                        <input matInput type="text"
                                                                            formControlName="ubicacion" required
                                                                            aria-describedby="addon-wrapping"
                                                                            (input)="updateInputValue($event, 'ubicacion')">
                                                                    </mat-form-field>


                                                                </div>


                                                                <!-- <div class="form-group"> -->
                                                                <div style="justify-content: end; margin-top: 1rem;">
                                                                    <button *ngIf="!existeContenido"
                                                                        [disabled]="spinnerEstudioMerca || generarMercado "
                                                                        type="submit"
                                                                        class="btn btn-primary align-items"
                                                                        [disabled]="firstFormGroup.invalid">
                                                                        <!-- <mat-spinner class="custom-spinner" ></mat-spinner> -->
                                                                        Generar Respuesta
                                                                        <div *ngIf="spinnerEstudioMerca"
                                                                            style="margin-left: 1rem;"
                                                                            class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </button>
                                                                </div>

                                                                <!-- </div> -->
                                                                <!--
                                                                <div class="col-md-4 columnas" >
            
                                                                    <div class="textarea-container" style="height: 75%;" >
                                                                        <textarea id="promptChatGPT" style="height: 100%;" class="form-control textArea" [(value)]="promptTxt"
                                                                            rows="4" placeholder="Prompt" readonly ></textarea>
                                                                        <button style="padding: 3px; "
                                                                            class="btn btn-outline-secondary copy-button" type="button"
                                                                            (click)="copyToClipboard()">
                                                                            <i style="margin: 0;" class="fa fa-copy"></i>
                                                                        </button>
                                                                        <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
            
                                                                    </div>
            
                                                                </div> -->
                                                            </div>
                                                            <div>

                                                            </div>

                                                        </form>


                                                    </mat-tab>
                                                    <mat-tab label="Respuesta">
                                                        <div [innerHTML]="respuestaMerca" class="tablaMerca">


                                                            <!-- <div class="form-group ">
                                                            <span>2.- Genera una respuesta con IA</span>
                                                            <ckeditor [editor]="Editor"
                                                            [data]="typedText">
                                                            </ckeditor>
                                                            </div> -->
                                                            <!-- <textarea [(value)]="typedText" style="height: 91%; width: 100%;"></textarea> -->
                                                        </div>

                                                        <div *ngIf="botonGuardarMerca && !existeContenido"
                                                            class="form-group">
                                                            <div style="justify-content: end; margin-top: 1rem;">
                                                                <button type="submit" class="btn btn-primary"
                                                                    (click)="guardarMercado()"
                                                                    [disabled]="firstFormGroup.invalid || guardarEstudio">Guardar</button><!--mat-button matStepperNext-->
                                                            </div>
                                                        </div>
                                                        <div *ngIf="existeContenido" class="form-group">
                                                            <div style="justify-content: end; margin-top: 1rem;">
                                                                <button type="submit" class="btn btn-primary"
                                                                    (click)="continuarMercado()">Continuar</button><!--mat-button matStepperNext-->
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!botonGuardarMerca" style="padding: 2rem;">
                                                            <span>** Te Falta generar una respuesta**</span>
                                                        </div>
                                                    </mat-tab>

                                                </mat-tab-group>


                                            </div>

                                        </div>
                                    </cdk-accordion-item>

                                    <cdk-accordion-item #accordionItem2="cdkAccordionItem"
                                        class="example-accordion-item" role="button" tabindex="0"
                                        id="accordion-header-2" [attr.aria-expanded]="accordionItem2.expanded"
                                        aria-controls="accordion-body-2">
                                        <div class="example-accordion-item-header" (click)="accordionItem2.toggle()">
                                            Investiga quién es tu competencía
                                            <span class="example-accordion-item-description">
                                                Click para {{ accordionItem2.expanded ? 'cerrar' : 'abrir' }}
                                            </span>
                                        </div>
                                        <div class="example-accordion-item-body" role="region"
                                            [style.display]="accordionItem2.expanded ? '' : 'none'"
                                            id="accordion-body-2" aria-labelledby="accordion-header-2">

                                            <div class="mt-4 bloque-item" *ngIf="banderaPasoCompet">
                                                <span class="bloque-title">Investiga quién es tu competencía</span>
                                                <div>
                                                    <form [formGroup]="estudioMerca" (ngSubmit)="estudioCompetencia()">
                                                        <div class="row">

                                                            <div><!--class="col-md-4 columnas"-->

                                                                <div class="form-group">
                                                                    <!-- <div class="row" style="margin-bottom: 1rem;">
                                                                            <label>Investiga quién es tu competencía</label>
                                                                        </div> -->
                                                                    <div class="row">
                                                                        <mat-form-field appearance="outline">
                                                                            <mat-label>Pega la Url del sitio web de tu
                                                                                competencia</mat-label>
                                                                            <input matInput type="text"
                                                                                formControlName="website1" required
                                                                                aria-describedby="addon-wrapping">
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div style="margin-bottom: 1rem;">
                                                            <button *ngIf="generarCompetencia && !continuarCompetencia"
                                                                [disabled]="spinnerCompetnecia || generarRespuesta "
                                                                type="submit" class="btn btn-primary align-items"
                                                                [disabled]="estudioMerca.invalid">
                                                                Generar Respuesta
                                                                <div *ngIf="spinnerCompetnecia"
                                                                    style="margin-left: 1rem;" class="spinner-border"
                                                                    role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </button>



                                                        </div>
                                                        <div class="form-group" style="height: 10rem;">



                                                            <!-- <ckeditor [editor]="Editor"  [data]="typedTextCompetencia" >
                                                                    </ckeditor>	 -->

                                                            <textarea *ngIf="respuestaCompetencia != undefined"
                                                                [(value)]="respuestaCompetencia"
                                                                style="height: 100%; width: 100%;"></textarea>


                                                            <!-- <div *ngIf="respuestaCompetencia == undefined">
                                                                        <span>** No se ha generado una respuesta**</span>
                                                                    </div> -->
                                                        </div>

                                                        <div
                                                            style="display: flex; margin-top: 1rem; justify-content: space-between;">



                                                            <!-- <div class="form-group"> -->
                                                            <!-- <div style="justify-content: end; margin-top: 1rem;"> -->

                                                            <button
                                                                *ngIf="(!continuar || generarRespuesta) && botonesGuardaryContinuar"
                                                                type="submit" class="btn btn-primary" mat-button
                                                                matStepperNext (click)="guardarCompetencia()"
                                                                [disabled]="estudioMerca.invalid || guardarRespuestaCompetencia">Guardar
                                                                y Continuar</button><!--mat-button matStepperNext-->
                                                            <!-- <button *ngIf="(continuar || !generarRespuesta) && botonesGuardaryContinuar"  class="btn btn-primary"  matStepperNext  [disabled]="estudioMerca.invalid " >Continuar</button> -->

                                                            <button *ngIf="continuarCompetencia" type="button"
                                                                mat-button matStepperNext
                                                                class="btn btn-primary align-items"
                                                                [disabled]="estudioMerca.invalid">
                                                                Continuar

                                                            </button>
                                                            <!-- </div> -->
                                                            <!-- </div> -->



                                                        </div>
                                                    </form>
                                                    <!-- <form [formGroup]="formGuardar" (ngSubmit)="enviarResChatGpt()"> -->
                                                    <!--
                                                            <div class="form-group">
                                                                <div style="justify-content: end; margin-top: 1rem;">
                                                                    <button (click)="notification()" type="submit" class="btn btn-primary" [disabled]="formGuardar.get('promptResChat').invalid || responseGPT.get('promptResChat').invalid" mat-button matStepperNext>Guardar Respuesta</button>
                                                                </div> -->
                                                    <!-- <div>
                                                                    <button class="btn btn-primary" mat-button matStepperNext>Siguente Paso</button>
                                                                </div> -->
                                                    <!-- </div> -->

                                                    <!-- </form>    -->


                                                </div>
                                            </div>

                                        </div>
                                    </cdk-accordion-item>
                                </cdk-accordion>

                            </div>
                        </mat-step>

                        <!-- <mat-step [stepControl]="estudioMerca">
                                <ng-template matStepLabel>Competencia</ng-template>

                            </mat-step> -->
                        <mat-step><!--[stepControl]="fromAvatarNom"-->

                            <ng-template matStepLabel>Avatar</ng-template>
                            <div class="mt-4">
                                <div class="bloque-item">
                                    <span class="bloque-title fontArial">Diseña tu Avatar</span>

                                    <div style="padding: 0 1rem 1rem 1rem;">
                                        <div class="row">
                                            <h4 class="fontArial">CÓMO SACARLE EL MÁXIMO PROVECHO:</h4>
                                        </div>
                                        <div class="row fontArial">
                                            <p>Esta hoja de trabajo requiere tiempo y esfuerzo, así que a mover las
                                                neuronas 😅. Ten en cuenta que todo lo que escribirás en este documento
                                                será tomando en cuenta <strong>el punto de vista de tu cliente</strong>.
                                                NO EL TUYO. **Ponte en sus zapatos** (literalmente).<br><br>

                                                Ten en cuenta que no puedes atender a todo el mundo. Sin especializarse
                                                nos volvemos generalistas, no podremos diferenciarnos y convertir
                                                nuestro negocio en una experiencia única.<br><br>

                                                Por último, recuerda que este ejercicio toma su tiempo, pero es clave
                                                para ayudarnos a desarrollar una identidad, un mensaje de marketing
                                                poderoso y una oferta diferente a la del resto.<br><br>

                                                <strong>Busca que tu producto tenga un factor WOW, alguna innovación que
                                                    lo haga único y MUY superior a tus competidores o a lo que ya hay en
                                                    el mercado.</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p class="fontArial" style="margin: 0; ">1.- Escribe palabras clave que describan todo lo
                                relacionado contigo y con tu producto.</p>
                            <!-- <mat-form-field class="example-chip-list" style="width: 100%;">
                                        <mat-label>Palabras clave</mat-label>
                                        <mat-chip-list #chipList aria-label="palabras clave">
                                            <mat-chip *ngFor="let nombreCurso of nombresCurso" [selectable]="selectable"
                                                    [removable]="removable" (removed)="remove(nombreCurso)">
                                            {{nombreCurso.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Palabras clave"
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="addOnBlur"
                                                    (matChipInputTokenEnd)="add($event)">
                                        </mat-chip-list>
                                    </mat-form-field> -->

                            <form [formGroup]="fromAvatarNom" (ngSubmit)="guardarPromptAvatar()">
                                <div class="row">
                                    <mat-form-field required class="example-chip-list" appearance="outline"
                                        style="margin-top: 1rem;">
                                        <mat-label>Palabras Clave</mat-label>
                                        <mat-chip-grid #chipGrid>
                                            @for (nombreCurso of nombresCurso; track nombreCurso) {
                                            <mat-chip-row (removed)="remove(nombreCurso)" [editable]="true"
                                                [aria-description]="'press enter to edit ' + nombreCurso.name">
                                                {{nombreCurso.name}}
                                                <button matChipRemove [attr.aria-label]="'remove ' + nombreCurso.name">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </mat-chip-row>
                                            }
                                            <input placeholder="Palabras clave" [matChipInputFor]="chipGrid"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
                                                formControlName="resAvatarNom" />
                                        </mat-chip-grid>

                                    </mat-form-field>
                                </div>

                                <div [innerHTML]="respuestaAvatar">
                                    <!-- <textarea style="width: 100%;">

                                            </textarea> -->

                                    <!-- <textarea [(value)]="textAvatar" style="height: 100%; width: 100%; height: 9rem" ></textarea> -->
                                </div>

                                <div class="form-group">
                                    <div style="justify-content: end; margin-top: 1rem;">
                                        <button *ngIf="!botonGuardarAvatar "
                                            [disabled]="spinnerAvatar || nombresCurso.length == 0 || actionName || generarNombre"
                                            type="submit" class="btn btn-primary align-items">
                                            Generar nombre
                                            <div *ngIf="spinnerAvatar" style="margin-left: 1rem;" class="spinner-border"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <hr>

                            <div class="form-group">
                                <div style="display: flex; margin-top: 1rem; justify-content: space-between;">

                                    <button *ngIf=" guardarContinuarAvatar" [disabled]="nombresCurso.length <= 0 "
                                        class="btn btn-primary" mat-button matStepperNext
                                        (click)="notifiacacionAvatar()">Guardar y
                                        Continuar</button><!--mat-button matStepperNext-->
                                    <button *ngIf="avatarContinuar" class="btn btn-primary" mat-button
                                        matStepperNext>Continuar</button>
                                </div>
                            </div>
                            <!-- <div class="row-md-6" style="margin-bottom: 1rem;">
                                        <p>2.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
                                        <div style="position: relative;">
                                            <textarea id="promptChatGPTAvatar" class="form-control textArea" [(value)]="promptPalabrasClave"
                                                rows="4" placeholder="Prompt" readonly style="height: 100%;"></textarea>
                                            <button style="padding: 3px; position: absolute; top: 0; right: 0;"
                                                class="btn btn-outline-secondary copy-button" type="button"
                                                (click)="copyToClipboardAvatar()">
                                                <i style="margin: 0;" class="fa fa-copy"></i>
                                            </button>
                                        </div>
                                    </div>


                                    <form [formGroup]="fromAvatarNom" >
                                        <div class="row">
                                            <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                <div class="form-group ">
                                                <span>3.- Pega la respuesta de chatGPT</span>
                                                <ckeditor [editor]="Editor"  [data]="textAvatar" >
                                                </ckeditor>
                                                </div>



                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div style="justify-content: end; margin-bottom: 1rem;">
                                                <button type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                            </div>
                                        </div>
                                    </form>  -->
                        </mat-step>

                        <mat-step [stepControl]="fourthFormGroup">
                            <ng-template matStepLabel class="fontArial">Estructura</ng-template>
                            <div class="mt-4">
                                <div class="bloque-item">
                                    <span class="bloque-title fontArial">Estructura del curso</span>
                                    <mat-tab-group #tabGroupMerca>
                                        <mat-tab label="Introducir datos">

                                            <div class="col-md-6" style="padding: 1rem;">
                                                <form [formGroup]="fourthFormGroup" (ngSubmit)="esturcturaCurso()">
                                                    <!-- <h4 style="margin-top: 1rem;">¿Cuál es tu profesión?</h4> -->

                                                    <div class="row">
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>¿Cuál es tu profesión?</mat-label>
                                                            <input matInput type="text" formControlName="QuienEres"
                                                                required aria-describedby="addon-wrapping"
                                                                (input)="updateInputValueAvatar($event)">
                                                        </mat-form-field>
                                                    </div>




                                                    <!-- <h4 style="margin-top: 1rem;">¿Qué tema deseas impartir en tu curso?</h4> -->
                                                    <div class="row">

                                                        <mat-form-field appearance="outline">
                                                            <mat-label>¿Qué tema deseas impartir en tu
                                                                curso?</mat-label>
                                                            <input matInput type="text"
                                                                formControlName="QueQuieresEnseñar" required
                                                                aria-describedby="addon-wrapping"
                                                                (input)="updateInputValueAvatarEn($event)">
                                                        </mat-form-field>
                                                    </div>


                                                    <!-- <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p> -->
                                                    <!-- <button type=submit class="btn btn-primary">Generar Estructura del Curso</button> -->

                                                    <button *ngIf="!continuarEstructura" [disabled]="spinnerEstructura "
                                                        type="submit" class="btn btn-primary align-items"
                                                        [disabled]="fourthFormGroup.invalid">
                                                        Generar Estructura del curso
                                                        <div *ngIf="spinnerEstructura" style="margin-left: 1rem;"
                                                            class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>

                                                </form>
                                            </div>

                                        </mat-tab>
                                        <mat-tab label="Respuesta">
                                            <!-- <div  [innerHTML]="respuestaEstructura" >



                                                </div>
                                                <ckeditor [editor]="Editor" [data]="respuestaEstructura">

                                                </ckeditor> -->
                                            <div>
                                                <textarea *ngIf="respuestaEstructura != undefined"
                                                    style="height: 260px; width: 100%;"
                                                    [(value)]="respuestaEstructura"></textarea>
                                                <p *ngIf="respuestaEstructura == undefined">**No haz generado ninguna
                                                    respuesta** </p>
                                            </div>

                                            <div class="form-group">
                                                <div style="justify-content: end; margin-top: 1rem;">

                                                    <button *ngIf="!continuarEstructura"
                                                        [disabled]="continuarGuardarEstructura" type="submit"
                                                        class="btn btn-primary" mat-button matStepperNext
                                                        (click)="notificacionEstructura()">Guardar y
                                                        Continuar</button><!--mat-button matStepperNext-->
                                                    <button *ngIf="continuarEstructura" class="btn btn-primary"
                                                        mat-button
                                                        matStepperNext>Continuar</button><!--mat-button matStepperNext-->

                                                </div>
                                            </div>



                                        </mat-tab>
                                    </mat-tab-group>


                                    <!-- <form [formGroup]="formProducto" (ngSubmit)="guradarPromptProducto()">
                                            <div class="row">
                                                <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                    <div class="form-group ">
                                                    <textarea id="resPromptProducto" class="form-control" style="margin-top: 2rem;" rows="4" placeholder="Prompt"
                                                        formControlName="resPromptProducto"
                                                        [ngClass]="{'error' :  formProducto.get('resPromptProducto').invalid  && banderaGuardarPrompt }"></textarea>
                                                        <span>2.- Pega la respuesta de chatGPT</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div style="justify-content: end; margin-bottom: 1rem;">
                                                    <button mat-button matStepperNext  [disabled]=" formProducto.get('resPromptProducto').invalid " (click)="notification()" type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                                </div>
                                            </div>
                                        </form> -->

                                </div>
                            </div>



                        </mat-step>

                        <mat-step label="Checklist">
                            <!-- <div class="accordion">
                                <cdk-accordion class="example-accordion" style="max-width: 100%;">
                                    Acordeón inicial para el primer módulo
                                    <cdk-accordion-item #accordionCheck="cdkAccordionItem"
                                        class="example-accordion-item" role="button" tabindex="0"
                                        id="accordion-header-0" [attr.aria-expanded]="accordionCheck.expanded"
                                        [attr.aria-controls]="'accordion-body-0'">

                                        <div class="example-accordion-item-header" (click)="accordionCheck.toggle()">
                                            <div class="module-title">
                                                <span class="module-title-text">Módulo 1.</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="custom-form-field">
                                                <mat-label>Título del Módulo</mat-label>
                                                <input matInput [(ngModel)]="modulos[0].Name" class="custom-input" />
                                            </mat-form-field>

                                            <span class="example-accordion-item-description">
                                                Click para {{ accordionCheck.expanded ? 'cerrar' : 'abrir' }}
                                            </span>
                                        </div>

                                        <div class="example-accordion-item-body" role="region"
                                            [style.display]="accordionCheck.expanded ? '' : 'none'"
                                            id="accordion-body-0" [attr.aria-labelledby]="'accordion-header-0'">

                                            Campos fijos para lecciones del primer módulo
                                            <div class="">
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título de la Lección 1</mat-label>
                                                    <input matInput [(ngModel)]="modulos[0].Topics[0].Title"
                                                        placeholder="Título de la Lección 1"
                                                        class="custom-input-content" />
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título de la Lección 2</mat-label>
                                                    <input matInput [(ngModel)]="modulos[0].Topics[1].Title"
                                                        placeholder="Título de la Lección 2"
                                                        class="custom-input-content" />
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título del Ejercicio Práctico</mat-label>
                                                    <input matInput [(ngModel)]="modulos[0].Topics[2].Title"
                                                        placeholder="Título del Ejercicio Práctico"
                                                        class="custom-input-content" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </cdk-accordion-item>

                                    Renderizado de módulos adicionales añadidos dinámicamente
                                    <ng-container *ngFor="let modulo of modulos.slice(1); let i = index">
                                        <cdk-accordion-item #accordionCheck="cdkAccordionItem"
                                            class="example-accordion-item" role="button" tabindex="0"
                                            id="accordion-header-{{i + 1}}"
                                            [attr.aria-expanded]="accordionCheck.expanded"
                                            [attr.aria-controls]="'accordion-body-' + (i + 1)">

                                            <div class="example-accordion-item-header"
                                                (click)="accordionCheck.toggle()">
                                                <div class="module-title">
                                                    <span class="module-title-text">Módulo {{i + 2}}.</span>
                                                </div>
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título del Módulo</mat-label>
                                                    <input matInput [(ngModel)]="modulo.Name"
                                                        placeholder="Título del Módulo" class="custom-input" />
                                                </mat-form-field>

                                                <span class="example-accordion-item-description">
                                                    Click para {{ accordionCheck.expanded ? 'cerrar' : 'abrir' }}
                                                </span>
                                            </div>

                                            <div class="example-accordion-item-body" role="region"
                                                [style.display]="accordionCheck.expanded ? '' : 'none'"
                                                id="accordion-body-{{i + 1}}"
                                                [attr.aria-labelledby]="'accordion-header-' + (i + 1)">

                                               
                                                <div>
                                                    <mat-form-field appearance="outline" class="custom-form-field">
                                                        <mat-label>Título de la Lección 1</mat-label>
                                                        <input matInput [(ngModel)]="modulo.Topics[0].Title"
                                                            placeholder="Título de la Lección 1"
                                                            class="custom-input-content" />
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="custom-form-field">
                                                        <mat-label>Título de la Lección 2</mat-label>
                                                        <input matInput [(ngModel)]="modulo.Topics[1].Title"
                                                            placeholder="Título de la Lección 2"
                                                            class="custom-input-content" />
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="custom-form-field">
                                                        <mat-label>Título del Ejercicio Práctico</mat-label>
                                                        <input matInput [(ngModel)]="modulo.Topics[2].Title"
                                                            placeholder="Título del Ejercicio Práctico"
                                                            class="custom-input-content" />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </cdk-accordion-item>
                                    </ng-container>
                                </cdk-accordion>

                                Botón para agregar nuevos módulos
                                <div class="form-group">
                                    <div class="button-container">
                                        <button mat-raised-button class="btn btn-primary"
                                            (click)="agregarModulo()">Agregar Módulo</button>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="form-group">
                                <div class="button-container-2">
                                    <button class="btn btn-primary" mat-raised-button matStepperNext
                                        (click)="enviarTodosLosModulos()">Guardar y Continuar</button>
                                </div>
                            </div> -->
                            <div class="card-container">
                                <!-- MÓDULO 0 -->
                                <button class="module-card" (click)="openModal(modalTemplate, 0)">
                                    <h3>MÓDULO 0 - {{ modulos[0].Name || 'Empieza aquí' }}</h3>
                                </button>

                                <!-- MÓDULO 1 -->
                                <button class="module-card" (click)="openModal(modalTemplate, 1)">
                                    <h3>MÓDULO 1 - {{ modulos[1].Name || 'Ejemplo de módulo 1' }}</h3>
                                </button>

                                <!-- MÓDULO 2 -->
                                <button class="module-card" (click)="openModal(modalTemplate, 2)">
                                    <h3>MÓDULO 2 - {{ modulos[2].Name || 'Ejemplo de módulo 2' }}</h3>
                                </button>

                                <!-- Renderizado de módulos adicionales añadidos dinámicamente -->
                                <ng-container *ngFor="let modulo of modulos.slice(3); let i = index">
                                    <button class="module-card" (click)="openModal(modalTemplate, i + 3)">
                                        <h3>MÓDULO {{ i + 3 }} - {{ modulo.Name || 'Nuevo Módulo' }}</h3>
                                    </button>
                                </ng-container>
                            </div>


                            <!-- Botón para agregar nuevos módulos -->
                            <div class="form-group">
                                <div class="button-container">
                                    <button mat-raised-button class="btn btn-primary" (click)="agregarModulo()">Agregar
                                        Módulo</button>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="button-container-2">
                                    <!-- Botón de Guardar -->
                                    <button *ngIf="!isContinueButtonVisible" mat-button matStepperNext
                                        class="btn btn-primary" (click)="handleSaveAndContinue()">Guardar y
                                        Continuar</button>

                                    <!-- Botón de Continuar (matStepperNext) -->
                                    <button *ngIf="isContinueButtonVisible" mat-button class="btn btn-primary"
                                        matStepperNext>Continuar</button>
                                </div>
                            </div>

                        </mat-step>

                        <mat-step label="Contenido">
                            <!-- <div class="mt-4" >
                                    <form [formGroup]="courseForm" (ngSubmit)="onSubmit()" class="needs-validation row">
                                        <div class="bloque-item">
                                            <span class="bloque-title">Datos generales</span>
                                            <div class="row"> -->
                            <!-- <div class="col-12 col-md-8">
                                                    <div class="row"> -->
                            <!-- <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="title">Título</label>
                                                                <input type="text" class="form-control" (keyup)="generateSlug($event)"
                                                                    [ngClass]="{'error' : submitted && courseForm.controls.Title.hasError('required') }"
                                                                    formControlName="Title" id="title" placeholder="Titulo">
                                                            </div>
                                                        </div> -->


                            <!-- <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label>Categoría:</label>
                                                                <select formControlName="category_id" name="category" class="custom-select form-select"
                                                                    required="">
                                                                    <option value="" selected disabled>Selecciona</option>
                                                                    <option *ngFor="let cat of categories" [value]="cat.id">{{
                                                                        cat.ValueAlpha }}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->

                            <!-- <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="title">Precio</label>
                                                                <input type="text" class="form-control" formControlName="UnitPrice" id="title"
                                                                    [ngClass]="{'error' : submitted && courseForm.controls.UnitPrice.hasError('required') }"
                                                                    placeholder="Precio">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label>Nivel: </label>
                                                                <select [formControlName]="'level_id'" name="level" class="custom-select form-select" required="">
                                                                    <option value="" selected disabled>Selecciona</option>
                                                                    <option *ngFor="let lvl of levelList" [value]="lvl.id">{{
                                                                        lvl.ValueAlpha }}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->


                            <!-- </div>
                                                </div> -->
                            <!-- <div class="col-12 col-md-4">
                                                    <label for="title">Agregar imagen del curso</label>
                                                    <ngx-dropzone (change)="onSelectImages($event)" [multiple]="false">
                                                        <ngx-dropzone-label class="label-file"> Arrastra una imagen aquí o haz clic
                                                            para seleccionar una
                                                            imagen desde tu computadora.</ngx-dropzone-label>
                                                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of imagesX" [file]="f"
                                                            [removable]="true">
                                                            <ngx-dropzone-label>{{ f.name.substr(1,10) }}... / ({{ f.type
                                                                }})</ngx-dropzone-label>
                                                        </ngx-dropzone-image-preview>
                                                    </ngx-dropzone>
                                                    <section *ngIf="responseImgFile" style="margin-top: 10px;">
                                                        <a [href]="responseImgFile" target="_black" style="color:#33bd38">imagen
                                                            cargada <i class="fa fa-check" aria-hidden="true"></i></a>
                                                    </section>
                                                </div> -->
                            <!-- </div>
                                        </div>

                                        <div class="bloque-item">
                                            <span class="bloque-title">Información del curso</span>
                                            <div class="row"> -->
                            <!-- <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Nivel: </label>
                                                        <select [formControlName]="'level_id'" name="level" class="custom-select form-select" required="">
                                                            <option value="" selected disabled>Selecciona</option>
                                                            <option *ngFor="let lvl of levelList" [value]="lvl.id">{{
                                                                lvl.ValueAlpha }}</option>
                                                        </select>
                                                    </div>
                                                </div> -->
                            <!-- <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="title">Recursos descargables</label>
                                                        <input type="text" class="form-control" formControlName="TotalHours" id="title"
                                                            [ngClass]="{'error' : submitted && courseForm.controls.TotalHours.hasError('required') }"
                                                            placeholder="Recursos descargables">
                                                    </div>
                                                </div> -->

                            <!-- <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Descripción</label>
                                                        <textarea class="form-control" formControlName="Description" id="description" rows="3"
                                                            placeholder="Ingresa una descripción del curso"></textarea>
                                                    </div>
                                                </div> -->
                            <!-- <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Lecturas</label>
                                                        <textarea class="form-control" formControlName="Introduction" id="description" rows="3"
                                                            placeholder="Ingresa las Lecutras del curso"></textarea>
                                                    </div>
                                                </div> -->
                            <!-- </div>


                                            <div class="row">
                                                <div class="col-md-6">

                                                    <div class="form-group">
                                                        <label for="description">Qué Aprenderas</label>
                                                        <textarea class="form-control" formControlName="Objective" id="description" rows="3"
                                                            placeholder="Ingresa lo que se aprendera en el curso"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">

                                                    <div class="form-group">
                                                        <label for="description">Dirigido a</label>
                                                        <textarea class="form-control" formControlName="TargetMarket" id="description" rows="3"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div> -->
                            <!-- <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Certificados</label>
                                                        <textarea class="form-control" formControlName="certificados" id="description" rows="3"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div> -->
                            <!-- </div>

                                        <div class="bloque-item">
                                            <span class="bloque-title">Plan de Estudio</span>
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Título</label>
                                                                <input class="form-control" id="validationCustom05" type="text"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.Name" name="name">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0"> Número de
                                                                    temas</label>
                                                                <input class="form-control" id="validationCustom05" type="number"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.numTopics" name="topics">
                                                            </div>
                                                        </div> -->
                            <!-- <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Porcentaje</label>
                                                                <input class="form-control" id="validationCustom05" type="number"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.Percentage" name="percent">
                                                            </div>
                                                        </div> -->
                            <!-- <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Duración</label>
                                                                <input class="form-control" id="validationCustom05" type="text"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.courseDuration" name="duration">
                                                            </div>
                                                        </div> -->
                            <!-- </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <label>Cargar video</label>
                                                    <ngx-dropzone (change)="onSelectVideo($event)" [multiple]="false">
                                                        <ngx-dropzone-label *ngIf="!viewLoaderVideo && module.VideoUrl === ''" class="label-file"> Arrastra aquí
                                                            el video del
                                                            módulo.</ngx-dropzone-label>
                                                        <div *ngIf="viewLoaderVideo" class="spinner-border text-danger" role="status">
                                                            <span class="sr-only">Cargando...</span>
                                                        </div>
                                                        <ngx-dropzone-label *ngIf="!viewLoaderVideo && module.VideoUrl !== ''" class="label-file">
                                                            <i class="fa fa-check text-success"></i>
                                                            Video cargado correctamente.
                                                        </ngx-dropzone-label>
                                                    </ngx-dropzone>
                                                </div>
                                                <div class="col-12 d-flex justify-content-end py-2">
                                                    <button [disabled]="module.VideoUrl === ''" class="btn btn-primary" (click)="addModule();">Agregar
                                                        módulo</button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <table *ngIf="modules.length !== 0" class="w-100">
                                                        <tr>
                                                            <th>Eliminar</th>
                                                            <th>Título</th>
                                                            <th>Número de temas</th>
                                                            <th>Porcentaje</th>
                                                            <th>Duración</th>
                                                        </tr>
                                                        <tr *ngFor="let module of modules">
                                                            <td>
                                                                <button class="btn btn-sm btn-primary"><i class="fa fa-trash mr-0 text-white"></i></button>
                                                            </td>
                                                            <td><b>{{ module.Name }}</b></td>
                                                            <td>{{ module.numTopics }}</td>
                                                            <td>{{ module.Percentage }}</td>
                                                            <td>{{ module.courseDuration }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form> -->
                            <!-- </div>
                                <button type="submit" class="btn btn-primary" >Crear curso</button>
                                <div class="mt-4">
                                    <div class="bloque-item" style="height: 33rem">
                                        <span class="bloque-title">Toma el curso OBS</span>

                                        <iframe
                                        [src]="iframeSrc"
                                        width="100%"
                                        height="100%"
                                        title="Iframe Example"
                                        frameborder="0"
                                        allowfullscreen
                                        sandbox="allow-scripts allow-same-origin"
                                        >
                                        </iframe>
                                    </div>
                                </div> -->
                            <app-new-product [productId]="productId"
                                (notifyParent)="getNotification($event)"></app-new-product>
                        </mat-step>

                        <mat-step label="Recursos">
                            <!-- <ng-template matStepLabel>Recursos</ng-template> -->
                            <div class="mt-4">
                                <div class="bloque-item" style="height: 33rem">
                                    <span class="bloque-title">Toma el curso OBS</span>

                                    <iframe [src]="iframeSrc" width="100%" height="100%" title="Iframe Example"
                                        frameborder="0" allowfullscreen sandbox="allow-scripts allow-same-origin">
                                    </iframe>
                                </div>
                            </div>
                            <cdk-accordion>
                                <!-- Filtramos los módulos para que no muestre el módulo 0 -->
                                <ng-container *ngFor="let modulo of modulos; let i = index">
                                    <!-- Solo mostramos los módulos con índice mayor a 0 -->
                                    <cdk-accordion-item *ngIf="i > 0" [expanded]="i === 1">
                                        <div class="cdk-accordion-header" (click)="togglePanel(i)">
                                            <span>{{ modulo.Name || 'Módulo ' + (i) }}</span>
                                        </div>

                                        <div class="cdk-accordion-body"
                                            *ngIf="modulo.Topics && modulo.Topics.length && expandedIndex === i">
                                            <div class="lecciones-container">
                                                <table class="lecciones-tabla">
                                                    <thead>
                                                        <tr>
                                                            <th>Lecciones</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let lesson of modulo.Topics; let j = index">
                                                            <td>{{ j + 1 }}. {{ lesson.Title }}</td>
                                                            <td>
                                                                <button (click)="onSelectVideo(i, j)">{{ lesson.uploaded
                                                                    ? 'Reemplazar video' : 'Subir video' }}</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </cdk-accordion-item>
                                </ng-container>
                            </cdk-accordion>
                            <!-- File Input Element for Video Upload -->
                            <input type="file" #fileInput accept="video/*" style="display:none"
                                (change)="onFileChange($event)">
                            <!-- <div class="row">
                                <label for="title">Banner del curso</label>
                                <div class="col-12 col-md-4">
                                    <img *ngIf="responseImgFile !== ''" class="w-100" [src]="responseImgFile" alt="">
                                    <img *ngIf="responseImgFile === ''" class="w-100"
                                        src="https://tse2.mm.bing.net/th/id/OIG3.Xz0M5tRen4dQ6MqpQbHZ?pid=ImgGn" alt="">
                                </div>
                                <div class="col-12 col-md-4">
                                    <ngx-dropzone (change)="onSelectImages($event)" [multiple]="false">
                                        <ngx-dropzone-label class="label-file"> Arrastra una imagen aquí o haz clic
                                            para seleccionar una
                                            imagen desde tu computadora.</ngx-dropzone-label>
                                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                            *ngFor="let f of imagesX" [file]="f" [removable]="true">
                                            <ngx-dropzone-label>{{ f.name.substr(1,10) }}... / ({{ f.type
                                                }})</ngx-dropzone-label>
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>
                                    <section *ngIf="responseImgFile" style="margin-top: 10px;">
                                        <a [href]="responseImgFile" target="_black" style="color:#33bd38">imagen
                                            cargada <i class="fa fa-check" aria-hidden="true"></i></a>
                                    </section>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="button-container-2">
                                    <button class="btn btn-primary" mat-raised-button (click)="getSlug()">Guardar y
                                        Continuar</button>
                                </div>
                            </div> -->
                        </mat-step>

                        <mat-step [stepControl]="secondFormGroup">
                            <form [formGroup]="secondFormGroup">
                                <ng-template matStepLabel>Ficha</ng-template>
                                <iframe [src]="'https://www.learnify.com.mx/curso/'+slug | safeUrl" class="w-100"
                                    style="height: 600px;" frameborder="0"></iframe>
                            </form>
                            <div class="form-group">
                                <div class="button-container-2">
                                    <button class="btn btn-primary" mat-raised-button
                                        (click)="setActiveCourse()">Publicar</button>
                                </div>
                            </div>
                        </mat-step>
                        <!-- <mat-step [stepControl]="secondFormGroup">
                                <form [formGroup]="secondFormGroup">
                                  <ng-template matStepLabel>Resumen</ng-template>
                                  <mat-form-field>
                                    <mat-label>Address</mat-label>
                                    <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                                           required>
                                  </mat-form-field>
                                  <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                  </div>
                                </form>
                            </mat-step> -->
                    </mat-stepper>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Template del Modal -->
<ng-template #modalTemplate>
    <!-- Módulo 0 -->
    <div *ngIf="currentModuloIndex === 0">
        <h2 class="titulo2">Introducción y Configuración del Módulo 0</h2>
        <div class="modal-modulo-0">
            <div class="text-content" [innerHTML]="module0Text"></div>

            <div class="ckeditor-container">
                <ckeditor [editor]="Editor" [(ngModel)]="module0Content" [config]="editorConfig"></ckeditor>
            </div>

            <div class="form-group">
                <div class="button-container">
                    <button mat-button class="btn btn-primary" (click)="dialogRef?.close()">Guardar</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="currentModuloIndex !== 0">
        <h2 class="titulo2">Módulo {{ currentModuloIndex }}</h2>
        <!-- Asegúrate de que el índice sea el correcto (1 basado en el módulo) -->
        <div class="modal-modulo">

            <!-- ======= -->
            <div *ngIf="currentModuloIndex !== 0" class="modal-n">
                <h2 class="titulo2">Módulo {{ currentModuloIndex }}</h2>
                <div class="modal-modulo">
                    <!-- Contenedor Principal -->
                    <div class="modulo-container">
                        <!-- Input para el nombre del módulo -->
                        <mat-form-field appearance="outline" class="custom-form-field">
                            <mat-label>Nombre del Módulo</mat-label>
                            <input matInput [(ngModel)]="modulos[currentModuloIndex].Name"
                                placeholder="Nombre del Módulo" />
                        </mat-form-field>

                        <div class="lecciones-container">
                            <!-- Columna de Inputs -->
                            <div class="columna-izquierda">
                                <!-- Lección 1 -->
                                <mat-form-field appearance="outline" class="custom-form-field">
                                    <mat-label>Título de la Lección 1</mat-label>
                                    <div class="input-button-container">
                                        <input matInput [(ngModel)]="modulos[currentModuloIndex].Topics[0].Title"
                                            placeholder="Título de la Lección 1" />
                                        <button class="btn btn-primary boton-inputs" mat-icon-button
                                            (click)="toggleLesson(0)">
                                            <mat-icon>{{ expandedLessons[0] ? 'keyboard_arrow_left' :
                                                'keyboard_arrow_right' }}</mat-icon>
                                        </button>
                                    </div>
                                </mat-form-field>

                                <!-- Lección 2 -->
                                <mat-form-field appearance="outline" class="custom-form-field">
                                    <mat-label>Título de la Lección 2</mat-label>
                                    <div class="input-button-container">
                                        <input matInput [(ngModel)]="modulos[currentModuloIndex].Topics[1].Title"
                                            placeholder="Título de la Lección 2" />
                                        <button class="btn btn-primary boton-inputs" mat-icon-button
                                            (click)="toggleLesson(1)">
                                            <mat-icon>{{ expandedLessons[1] ? 'keyboard_arrow_left' :
                                                'keyboard_arrow_right' }}</mat-icon>
                                        </button>
                                    </div>
                                </mat-form-field>

                                <!-- Ejercicio Práctico -->
                                <mat-form-field appearance="outline" class="custom-form-field">
                                    <mat-label>Título del Ejercicio Práctico</mat-label>
                                    <div class="input-button-container">
                                        <input matInput [(ngModel)]="modulos[currentModuloIndex].Topics[2].Title"
                                            placeholder="Título del Ejercicio Práctico" />
                                        <button class="btn btn-primary boton-inputs" mat-icon-button
                                            (click)="toggleLesson(2)">
                                            <mat-icon>{{ expandedLessons[2] ? 'keyboard_arrow_left' :
                                                'keyboard_arrow_right' }}</mat-icon>
                                        </button>
                                    </div>
                                </mat-form-field>
                            </div>

                            <!-- Columna de Contenido Expandido -->
                            <div class="columna-derecha" *ngIf="expandedLessons.includes(true)">
                                <div *ngIf="expandedLessons[0]" class="expan-derecha">
                                    <ckeditor [editor]="Editor"
                                        [(ngModel)]="modulos[currentModuloIndex].Topics[0].Content"
                                        [config]="editorConfig"></ckeditor>
                                    <br>
                                    <ngx-dropzone (change)="onResourceSelect($event, currentModuloIndex, 0)"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4>Suelta archivos aquí o haz clic para subir.</h4>
                                            </div>
                                        </ngx-dropzone-label>
                                    </ngx-dropzone>
                                    <div *ngIf="modulos[currentModuloIndex].Topics[0].Files.length > 0">
                                        <ul>
                                            <li
                                                *ngFor="let file of modulos[currentModuloIndex].Topics[0].Files; let i = index">
                                                {{ file.name }}
                                                <button mat-icon-button (click)="removeFile(currentModuloIndex, 0, i)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div *ngIf="expandedLessons[1]" class="expan-derecha">
                                    <ckeditor [editor]="Editor"
                                        [(ngModel)]="modulos[currentModuloIndex].Topics[1].Content"
                                        [config]="editorConfig"></ckeditor>
                                    <br>
                                    <ngx-dropzone (change)="onResourceSelect($event, currentModuloIndex, 1)"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4>Suelta archivos aquí o haz clic para subir.</h4>
                                            </div>
                                        </ngx-dropzone-label>
                                    </ngx-dropzone>
                                    <div *ngIf="modulos[currentModuloIndex].Topics[1].Files.length > 0">
                                        <ul>
                                            <li
                                                *ngFor="let file of modulos[currentModuloIndex].Topics[1].Files; let i = index">
                                                {{ file.name }}
                                                <button mat-icon-button (click)="removeFile(currentModuloIndex, 1, i)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div *ngIf="expandedLessons[2]" class="expan-derecha">
                                    <ckeditor [editor]="Editor"
                                        [(ngModel)]="modulos[currentModuloIndex].Topics[2].Content"
                                        [config]="editorConfig"></ckeditor>
                                    <br>
                                    <ngx-dropzone (change)="onResourceSelect($event, currentModuloIndex, 2)"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4>Suelta archivos aquí o haz clic para subir.</h4>
                                            </div>
                                        </ngx-dropzone-label>
                                    </ngx-dropzone>
                                    <div *ngIf="modulos[currentModuloIndex].Topics[2].Files.length > 0">
                                        <ul>
                                            <li
                                                *ngFor="let file of modulos[currentModuloIndex].Topics[2].Files; let i = index">
                                                {{ file.name }}
                                                <button mat-icon-button (click)="removeFile(currentModuloIndex, 2, i)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div mat-dialog-actions>
                        <button mat-button class="btn btn-primary" (click)="dialogRef?.close()">Guardar
                            Contenido</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12 pl-0">
                                                    <h4>Cargar imagen de portada</h4>
                                                    <img *ngIf="ImgFileUrl" [src]="[ImgFileUrl]" class="img-fluid blur-up lazyload bg-img mb-3" alt="" style="width: 200px;">
                                                    <div *ngIf="previewImg" class="box-input-file">
                                                        <img class="imgUpload" alt="" [src]="previewImg.base">
                                                    </div>
                                                    <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <input type="file" name="picture1" class="file-input" accept=".png, .jpg, .jpeg" (change)="onImgSelected($event)" #fileImgUpload>
                                                    <div class="file-upload mt-3">
                                                        <button class="btn btn-primary" (click)="fileImgUpload.click()">
                                                            {{ImgFileUrl ? 'Reemplazar imagen' : "Cargar imagen"}}
                                                            <i class="ml-3 fa fa-cloud-upload" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>


                                                <div class="col-12 mt-5 pl-0">
                                                    <h4>Cargar manual del producto</h4>
                                                    <div *ngIf="previewFile" class="box-input-file py-3">
                                                        <a href="{{previewFile}}" target="_blank" class=""  ><h4>Ver archivo</h4></a>
                                                    </div>
                                                    <div *ngIf="viewLoaderFile" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <input type="file" class="file-input" accept=".pdf" (change)="onFileSelected($event)" #fileUpload>
                                                    <div class="file-upload">
                                                        <button class="btn btn-primary" (click)="fileUpload.click()">
                                                            {{previewFile ? 'Reemplazar archivo' : "Cargar archivo"}}
                                                            <i class="fa fa-cloud-upload ml-3" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom01" type="text" [formControl]="productForm.controls['Title']"
                                                [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">SKU</label>
                                                <input class="form-control" id="validationCustom02" type="text" [formControl]="productForm.controls['SKU']"
                                                [ngClass]="{'error': submitted && formState?.SKU?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">{{ ruta == 'add-course' ? 'Modelo del curso' : 'Modelo de capacitación'}}</label>
                                                <input class="form-control" id="validationCustom03" type="text" [formControl]="productForm.controls['CourseModel']"
                                                [ngClass]="{'error': submitted && formState?.CourseModel?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom10" class="col-form-label pt-0">Total de horas</label>
                                                <input class="form-control" id="validationCustom10" type="number" min="0" oninput="validity.valid||(value='');"
                                                [formControl]="productForm.controls['TotalHours']"
                                                [ngClass]="{'error': submitted && formState?.TotalHours?.errors?.required || formState?.TotalHours?.errors?.min}">
                                                <span *ngIf="formState?.TotalHours?.errors?.min" class="text-danger">
                                                    No se permiten valores negativos
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom11" class="col-form-label pt-0">Lenguaje</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['Language']"
                                                [ngClass]="{'error': submitted && formState?.Language?.errors?.required}">
                                                    <option value="">Selecciona lenguaje</option>
                                                    <option value="Español">Español</option>
                                                    <option value="Inglés">Inglés</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom12" class="col-form-label pt-0">Lenguaje de subtítulos</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['LanguageSubtitles']"
                                                [ngClass]="{'error': submitted && formState?.LanguageSubtitles?.errors?.required}">
                                                    <option value="">Selecciona subtítulos</option>
                                                    <option value="Español">Español</option>
                                                    <option value="Inglés">Inglés</option>
                                                    <option value="N/A">N/A</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom07" class="col-form-label pt-0">Categoría</label>
                                                <ng-select name="Category" id="Category" [formControl]="productForm.controls['Category']" [ngClass]="{'error': submitted && formState?.Category?.errors?.required}">
                                                    <ng-option *ngFor="let category of categories" [value]="category.ValueAlpha">
                                                        {{category.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom08" class="col-form-label pt-0">Sub categoría</label>
                                                <ng-multiselect-dropdown
                                                    [placeholder]="'Seleccionar subcategorías'"
                                                    [settings]="dropdownSettings"
                                                    [formControl]="productForm.controls['SubCategory']"
                                                    [data]="subcategoryList"
                                                    (onSelect)="selectSubCategory($event)"
                                                    (onDeSelect)="deselectSubCategory($event)">
                                                    >
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom09" class="col-form-label pt-0">Nivel</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['Level']"
                                                [ngClass]="{'error': submitted && formState?.Level?.errors?.required}">
                                                    <option value="">Selecciona nivel</option>
                                                    <option value="Principiante">Principiante</option>
                                                    <option value="Intermedio">Intermedio</option>
                                                    <option value="Avanzado">Avanzado</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Precio</label>
                                                <input class="form-control" id="validationCustom04" type="number" [formControl]="productForm.controls['UnitPrice']" min="0" oninput="validity.valid||(value='');"
                                                [ngClass]="{'error': submitted && formState?.UnitPrice?.errors?.required || formState?.UnitPrice?.errors?.min}">
                                                <span *ngIf="formState?.UnitPrice?.errors?.min" class="text-danger">
                                                    No se permiten valores negativos
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom17" class="col-form-label pt-0">Precio descuento</label>
                                                <input class="form-control" id="validationCustom17" type="number" min="0" [formControl]="productForm.controls['DiscountPrice']" oninput="validity.valid||(value='');">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom05" class="col-form-label pt-0">Moneda</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['CurrencyCode']"
                                                [ngClass]="{'error': submitted && formState?.CurrencyCode?.errors?.required}">
                                                    <option value="">Selecciona moneda</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Tipo de acceso</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['AccessType']"
                                                [ngClass]="{'error': submitted && formState?.AccessType?.errors?.required}">
                                                    <option value="">Selecciona acceso</option>
                                                    <option value="Online">Online</option>
                                                    <option value="Presencial">Presencial</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom13" class="col-form-label pt-0">Descripción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Description']" name="Description" id="validationCustom13" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom14" class="col-form-label pt-0">Introducción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Introduction']" name="Introduction" id="validationCustom14" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.Introduction?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom15" class="col-form-label pt-0">Objetivo</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Objective']" name="Objective" id="validationCustom15" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.Objective?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom16" class="col-form-label pt-0">Dirigido a</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['TargetMarket']" name="TargetMarket" id="validationCustom16" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.TargetMarket?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                <div class="form-group col-12 mb-0 pr-0">
                                    <div class="product-buttons button-save text-right pr-0">
                                        <button class="btn btn-primary"
                                            [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando, espera' : 'Guardar'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons button-save text-right pr-0">
                                        <button type="submit" class="btn btn-primary" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando, espera' : 'Editar'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->