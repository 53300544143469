import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TemplateRef } from '@angular/core';
import { ProductsService } from 'src/app/shared/service/product.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public formSubmitted: boolean = false; 
  public active = 1;

  public ultimoGradoEstudios: string = '';
  public carrera: string = '';
  public escuela: string = '';
  public aboutMe: string = '';
  public fbUrl: string = '';
  public xUrl: string = '';
  public lnUrl: string = '';
  public nuevoAntecedente = { empresa: '', cargo: '', periodoInicio: '', periodoFin: '' };
  public antecedentes: { empresa: string, cargo: string, periodoInicio: string, periodoFin: string }[] = [];

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private productService: ProductsService // Inyectar servicio
  ) { }

  ngOnInit() { }

  mostrarFormulario(templateRef: TemplateRef<any>): void {
    const dialogRef = this.dialog.open(templateRef, {
      width: '500px',
      position: { top: '100px' },
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  checkCharacterLimit() {
    if (this.aboutMe.length >= 300) {
      this.aboutMe = this.aboutMe.substring(0, 300);  
    }
  }

  guardarAntecedente() {
    if (this.nuevoAntecedente.empresa && this.nuevoAntecedente.cargo && this.nuevoAntecedente.periodoInicio) {
      this.antecedentes.push({ ...this.nuevoAntecedente });
      console.log(this.antecedentes);
      this.nuevoAntecedente = { empresa: '', cargo: '', periodoInicio: '', periodoFin: '' };
      this.dialog.closeAll(); // si quiero que dar clic en cerrar, lo pongo como comentario
    }
  }

  enviarDatosPerfil() {
    const data = {
      ultimoGradoEstudios: this.ultimoGradoEstudios,
      carrera: this.carrera,
      escuela: this.escuela,
      aboutMe: this.aboutMe,
      antecedentes: this.antecedentes,
      fbUrl: this.fbUrl,
      xUrl: this.xUrl,
      lnUrl: this.lnUrl,
      periodoInicio: this.nuevoAntecedente.periodoInicio,  
      periodoFin: this.nuevoAntecedente.periodoFin         
    };

    // Envía los datos usando el servicio
    this.productService.updateProfile(data).subscribe(
      response => {
        console.log('Datos enviados correctamente:', response);
        this.formSubmitted = true; // Cambia el estado cuando el formulario se envía correctamente
      },
      error => {
        console.error('Error al enviar los datos:', error);
        this.formSubmitted = false;  // Puedes decidir si mantenerlo como false en caso de error
      }
    );
  }
}

