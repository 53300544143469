import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  googleSearch } from 'src/environments/environment';

// const googleApi = 'https://www.googleapis.com/customsearch/v1';
const googleLink:string = googleSearch.googleLink;
const googlekey: string = googleSearch.key;
const googleContext: string = googleSearch.googleContext;


@Injectable({
  providedIn: 'root'
})
export class GoogleSearchService {

  

  constructor(private http: HttpClient) { }

  
  searchByGoogle(busqueda: string){
    return this.http.get<any>(`${googleLink}?key=${googlekey}&cx=${googleContext}&q=${busqueda}` );
  }

}
