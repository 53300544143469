<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <img [src]="productInfo?.ImgFileUrl" width="100%" alt="">
                <div class="w-100 d-flex justify-content-between mt-3">
                    <a href="{{productInfo?.ImgFileUrl}}" target="_blank" download="" class="mb-2 btn btn-primary">
                        <i class="fa fa-download m-0"></i> Descargar
                    </a>
                    <button (click)="imageFile.click()" class="mb-2 lm-2 btn btn-primary">
                        <i class="fa fa-upload m-0"></i> Reemplazar
                    </button>
                </div>
                <input type="file" style="display: none;" (change)="onSelectImages($event)" #imageFile>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                    <h2 class="d-flex justify-content-between">{{ productInfo?.Title }} <button class="btn"
                            [ngClass]="{'btn-primary' : productInfo?.Active, 'btn-secondary' : !productInfo?.Active,}">{{
                            productInfo.Active ? 'Publicado' : 'En revisión' }}</button></h2>
                    <h6 class="product-title">Categoría</h6>
                    <p class="mb-0">{{ productInfo?.Category.ValueAlpha }}</p>
                    <h6 class="product-title">Nivel</h6>
                    <p class="mb-0">{{ productInfo?.Level.ValueAlpha }}</p>
                    <div class="product-price digits mt-2">
                        <h3>{{ productInfo?.UnitPrice | currency: 'USD' }}</h3>
                    </div>
                    <hr>
                    <h6 class="product-title">Descripción</h6>
                    <p class="mb-0">{{ productInfo?.Description }}</p>
                    <h6 class="product-title">¿Qué aprenderas?</h6>
                    <p class="mb-0">{{ productInfo?.Objective }}</p>
                    <h6 class="product-title">¿A quién va dirigido?</h6>
                    <p class="mb-0">{{ productInfo?.TargetMarket }}</p>
                    <hr>
                    <div class="product-price digits mt-2">
                        <h3>Plan de Estudio</h3>
                        <div *ngFor="let module of productInfo?.product; let i = index">
                            <h6 class="product-title">Módulo {{ i }}. {{ module.Name }}</h6>
                            <ul>
                                <li *ngFor="let topic of module?.Topics; let j = index">
                                    <span> Lección {{ j+1 }}. {{ topic?.Title }}</span>
                                    <div class="row mt-2">
                                        <div class="col-6">
                                            <h5>Recursos</h5>
                                            <span *ngIf="topic?.Resources.length === 0">No hay recursos para esta
                                                lección</span>
                                            <a class="btn btn-secondary" target="_blank" download=""
                                                href="{{ resource.Value }}"
                                                *ngFor="let resource of topic?.Resources">Descargar</a>
                                        </div>
                                        <div class="col-6">
                                            <h5>Video</h5>
                                            <a href="{{topic?.videoUrl}}"
                                                *ngIf="!topic.vimeoUploading && topic.videoUrl && topic.videoUrl.includes('learnify')"
                                                target="_blank" download="" class="mb-2 btn btn-primary">
                                                <i class="fa fa-download m-0"></i> Descargar
                                            </a> <br>
                                            <a href="{{topic?.videoUrl}}"
                                                *ngIf="topic.videoUrl && topic.videoUrl.includes('vimeo')"
                                                target="_blank" class="mb-2 btn btn-primary">
                                                <i class="fa fa-eye m-0"></i> Ver en vimeo
                                            </a> <br>
                                            <button
                                                *ngIf="!topic.vimeoUploading && topic.videoUrl && topic.videoUrl.includes('learnify')"
                                                class="mb-2 btn btn-secondary d-flex align-items-center"
                                                [disabled]="topic.uploading" (click)="videoRemote.click()">
                                                <i class="fa fa-upload m-0"></i> {{ topic.uploading ? 'Cargando video' :
                                                topic?.videoUrl ? 'Reemplazar' :
                                                'Cargar video'}}
                                                <div *ngIf="topic.uploading" style="margin-left: 1rem;"
                                                    class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </button>
                                            <button *ngIf="!topic.videoUrl"
                                                class="mb-2 btn btn-secondary d-flex align-items-center"
                                                [disabled]="topic.uploading" (click)="videoRemote.click()">
                                                <i class="fa fa-upload m-0"></i> {{ topic.uploading ? 'Cargando video' :
                                                topic?.videoUrl ? 'Reemplazar' :
                                                'Cargar video'}}
                                                <div *ngIf="topic.uploading" style="margin-left: 1rem;"
                                                    class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </button>
                                            <input type="file" style="display: none;"
                                                (change)="updateVideo($event, topic, i, j)" #videoRemote>
                                            <button class="mb-2 btn btn-primary d-flex align-items-center"
                                                (click)="sharedToVimeo(topic)"
                                                *ngIf="topic.videoUrl && topic?.videoUrl.includes('learnify')">
                                                <i class="fa fa-cloud-upload m-0"></i> {{ !topic.vimeoUploading ?
                                                'Publicar en Vimeo' : 'Publicando...' }}
                                                <div *ngIf="topic.vimeoUploading" style="margin-left: 1rem;"
                                                    class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </button> <br>
                                        </div>
                                    </div>
                                    <hr>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-end" *ngIf="!productInfo.Active">
                        <button (click)="setActive()" class="btn btn-primary">Publicar en
                            Learnify</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>