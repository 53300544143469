<div class="row mb-4">
    <div class="col-xs-12 col-sm-6 centertb">
        <p class="no-mb">Mis cursos
            <strong>{{totalProducts}}</strong>
        </p>
    </div>
    <div class="col-xs-12 col-sm-12">
        <div class="action d-flex justify-content-end align-items-center">
            <div class="d-flex align-items-center" *ngIf="showFilter" style="flex: 1; margin-right: 10px;">
                <div style="flex: 1; margin-right: 10px;">
                    <label class="sr-only">Buscar por nombre o SKU</label>
                    <div class="input-group" style="width: 100%;">
                        <input type="text" class="form-control" style="width: 100%;" [(ngModel)]="search"
                            (ngModelChange)="updateSearchValue($event)" [ngModelOptions]="{standalone: true}"
                            (keyup)="loadData()" placeholder="Buscar por nombre o SKU">
                    </div>
                </div>
                <button *ngIf="search" class="btn btn-danger" style="white-space: nowrap; margin-right: 10px;"
                    (click)="clearSearch()">
                    Borrar Filtro
                </button>
            </div>
            <div class="d-flex">
                <a class="btn btn-secondary mx-2" style="white-space: nowrap;" (click)="toggleFilter()">Filtrar</a>
                <a class="btn btn-primary btn-sm" style="white-space: nowrap;" [routerLink]="['add-course']">
                    <span>Agregar</span>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="table-template">
    <table class=" table table-lg table-hover row-border hover">
        <thead>
            <tr>
                <th>Imagen</th>
                <th>Título</th>
                <th>Precio</th>
                <th>Categoría</th>
                <th>Nivel</th>
                <th>Total de horas</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let course of product_list">
                <td><img width="80px" height="auto" [src]="course.ImgFileUrl"></td>
                <td>{{course.Title}}</td>
                <td>{{course.UnitPrice| currency:'MXN':'symbol-narrow':'1.2-2' }}</td>
                <td>{{course?.Category?.ValueAlpha}}</td>
                <td>{{course?.Level?.ValueAlpha}}</td>
                <td>{{course.TotalHours}}</td>
                <td class="actions-cell">
                    <a [routerLink]="['show', course.id]" mat-icon-button class="mx-3"
                        aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon>remove_red_eye</mat-icon>
                    </a>
                    <a [routerLink]="['edit', course.id]" mat-icon-button
                        aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <!-- <div class="opciones">
                        Opciones <i class="fa fa-caret-down ml-1" aria-hidden="true"></i> <br>

                        <ul>
                            <li [routerLink]="['show', course.id]">Ver curso</li>
                            <li [routerLink]="['edit', course.id]">Editar curso</li>
                            <li [routerLink]="['view-programs', course.id]">Agregar y/o ver módulos</li>
                            <li>{{course.Active === true ?
                                'Ocultar' : 'Publicar'}}</li>
                            <li>Previsualizar curso</li>
                        </ul>
                    </div> -->
                </td>
            </tr>
        </tbody>
    </table>
</div>