import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class LandingsService {

  constructor(private http: HttpClient) { }


  getLandinPage(slug: any){
    return this.http.get<any>(`${BASE_URL}/landing/${slug}`);
  }
}
