import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { lastValueFrom } from 'rxjs';
import { ProductsService } from 'src/app/shared/service/product.service';
import * as Notiflix from 'notiflix';
import { DomSanitizer } from '@angular/platform-browser';

export interface nombreCursos {
  name: string;
}

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrl: './new-product.component.scss'
})
export class NewProductComponent {
  @Input() productId;
  public ImgfileName = '';
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  public categorySelected = null;
  public levelSelected = null;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public responseImgFile: string = '';
  public previewImg: any;
  public viewLoaderImg = false;
  public viewLoaderFile = false;
  public previewFile: any;
  file: File;
  public responseFile: string = '';
  imagesX: File[] = [];
  public course = {
    id: 0,
    TargetMarket: '',
    Objective: '',
    CurrencyCode: 'MXN',
    product: [],
    Type: 'Course',
    Family: 'Course',
    user_id: 0,
    category_id: 0,
    level_id: 0,
    teacher_id: 0,
    Title: '',
    Slug: 'https://www.learnify.com.mx/curso/example',
    Category: 0,
    Level: 0,
    active: true,
    UnitPrice: '0',
    Description: '',
    ImgFileUrl: ''
  }

  public categories = [];
  public levels = [];

  currentUser: any;
  learning: any = [];
  target: any = [];
  step = 0;

  constructor(
    private productService: ProductsService,
    private sanitizer: DomSanitizer,
  ) {
    this.loadData();
  }

  removeTarget(fruit: any): void {
    const index = this.target.indexOf(fruit);
    if (index >= 0) {
      this.target.splice(index, 1);
    }
  }

  saveProduct() {

    const target = this.target.map((t: any) => { return t.name })
    const objetive = this.learning.map((t: any) => { return t.name })

    this.course.id = this.productId;
    this.course.user_id = this.currentUser.id;
    this.course.teacher_id = this.currentUser.id;
    this.course.category_id = this.course.Category;
    this.course.level_id = this.course.Level;
    this.course.TargetMarket = target.join(",");
    this.course.Objective = objetive.join(",");
    this.course.Slug = this.course.Slug.split("/").pop();
    this.productService.updateCourse2(this.productId, this.course).subscribe((data: any) => {
      Notiflix.Notify.success('Guardado con exito');
      this.notifyParent.emit(this.course);
    });
  }

  addTarget(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.target.push({ name: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.learning.indexOf(fruit);
    if (index >= 0) {
      this.learning.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.learning.push({ name: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  selectCategory(event) {
    console.log(this.categories);
    this.categorySelected = this.categories.find(cat => cat.id === parseInt(event.target.value));
  }

  selectLevel(event) {
    console.log(this.levels);
    this.levelSelected = this.levels.find(cat => cat.id === parseInt(event.target.value));
  }

  async loadData() {
    this.currentUser = JSON.parse(localStorage['currentUser']);
    let catalogs = this.productService.getCatalogsName('Category');
    this.categories = await lastValueFrom(catalogs);
    catalogs = this.productService.getCatalogsName('Level');
    this.levels = await lastValueFrom(catalogs);
    console.log(this.categories, this.levels)
    const product = this.productService.getProduct(this.productId);
    const productData = await lastValueFrom(product);

    const productInfo = productData[0];
    if (productInfo.category_id) {
      this.categorySelected = this.categories.find((cat: any) => { cat.id = productInfo.category_id })
    }
    if (productInfo.level_id) {
      this.levelSelected = this.levels.find((cat: any) => { cat.id = productInfo.category_id })
    }

    this.course.Category = productInfo.category_id || '';
    this.course.Level = productInfo.level_id || '';
    this.course.Title = productInfo.Title;
    this.course.Description = productInfo.Description;
    this.course.UnitPrice = productInfo.UnitPrice;
    this.course.ImgFileUrl = productInfo.ImgFileUrl;
    this.learning = productInfo.Objective ? productInfo.Objective.split(",").map((o: any) => { return { name: o } }) : [];
    this.target = productInfo.TargetMarket ? productInfo.TargetMarket.split(",").map((o: any) => { return { name: o } }) : [];

    this.course.Slug = 'https://www.learnify.com.mx/curso/' + productInfo.Slug;
    this.course.id = productInfo.id;
  }

  onSelectImages(event) {
    this.imagesX = [];
    this.imagesX.push(...event.addedFiles);
    this.file = this.imagesX[0];

    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('type', 'images');
      this.productService.uploadFile2(formData)
        .subscribe(resolve => {
          if (resolve.FileUrl !== null) {
            this.responseImgFile = resolve.ImageUrl;
            const body = {
              id: this.productId,
              ImgFileUrl: resolve.ImageUrl
            };
            this.productService.updateCourse2(this.productId, body).subscribe((data: any) => {
              console.log("ok");
            });
          }
        });
    }

    this.viewBase64(this.file).then(image => {
      this.previewImg = image;
    })
    this.ImgfileName = this.file.name;
  }

  generateSlug(event) {
    const value = event.target.value;
    const newString = value.toString().toLowerCase().replace(/[^A-Z0-9]/ig, "-");
    this.course.Slug = 'https://www.learnify.com.mx/curso/' + newString;
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

}

