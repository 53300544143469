import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from 'src/app/shared/service/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [NgbRatingConfig]
})
export class ProductDetailComponent implements OnInit {
  productInfo: any;

  constructor(
    private route: Router,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute
  ) { }

  updateVideo(event: any, topic: any, moduloNumero, leccionNumero) {
    const file = event.target.files[0];
    if (file) {
      topic.uploading = true;
      const formData = new FormData();
      formData.append('name', `Modulo ${moduloNumero}, Lección ${leccionNumero}`);
      formData.append('type', 'videos');
      formData.append('videoupload', file);

      this.productService.uploadVideoModulo(formData).subscribe(
        (response) => {
          topic.uploaded = true;
          const newtopic = {
            videoUrl: response.videoUrl
          }
          this.productService.updateTopic(topic.id, newtopic).subscribe((data: any) => {
            topic.uploading = false;
            Notiflix.Notify.success('Video cargado correctamente');
            topic.videoUrl = response.videoUrl;
          })
        },
        (error) => {
          console.error(`Error al subir el video ${file.name} para el módulo ${moduloNumero}, lección ${leccionNumero}:`, error);
        }
      );
    }
  }

  ngOnInit(): void {
    this.loadData();
  }
  async loadData() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    const query = this.productService.getProduct(id);
    this.productInfo = await lastValueFrom(query);

    const resources = this.productInfo.ProductInfo.filter((pi: any) => pi.Property === 'Resource');
    this.productInfo.product.forEach(element => {
      element.Topics = element.Topics.map((element: any) => {
        return {
          ...element,
          uploading: false,
          vimeoUploading: false,
          Resources: resources.filter((r: any) => r.Commentary === element.id.toString())
        }
      })
    });
  }

  setActive() {
    const body = {
      id: this.productInfo.id,
      Active: true
    }
    this.productService.updateCourse2(this.productInfo.id, body).subscribe((data: any) => {
      Notiflix.Notify.success('Curso pulicado correctamente');
      this.route.navigateByUrl('/products/courses');
    });
  }

  sharedToVimeo(topic: any) {
    topic.vimeoUploading = true;
    const body = {
      name: topic.Title,
      path: topic.videoUrl,
    }

    this.productService.sharedVideo(body).subscribe((data: any) => {
      const newtopic = {
        videoUrl: data.videoUrl,
      }
      this.productService.updateTopic(topic.id, newtopic).subscribe((data: any) => {
        topic.videoUrl = data.videoUrl;
        topic.vimeoUploading = false;
        Notiflix.Notify.success('Video publicado correctamente')
      });
    })

  }

  onSelectImages(event) {
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'images');
      this.productService.uploadFile2(formData)
        .subscribe(resolve => {
          if (resolve.FileUrl !== null) {
            const body = {
              id: this.productInfo.id,
              ImgFileUrl: resolve.ImageUrl
            };
            this.productService.updateCourse2(this.productInfo.id, body).subscribe((data: any) => {
              this.productInfo.ImgFileUrl = resolve.ImageUrl;
              Notiflix.Notify.success('Imagen cargada correctamente')
            });
          }
        });
    }
  }

}

