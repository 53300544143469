<!-- Container-fluid starts-->
<div class="row mb-4">
  <div class="col-xs-12 col-sm-12">
    <div class="table-template">
      <!-- Filtros que siempre estarán visibles -->
      <div class="filter-container mb-3">
        <!-- Mensaje de Filtrar -->
        <strong>Filtrar por: </strong>

        <!-- Barra de búsqueda -->
        <div class="search-bar">
          <label class="sr-only">Buscar por nombre</label>
          <div class="input-group" style="width: 100%;">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="search"
              (ngModelChange)="updateSearchValue($event)" [ngModelOptions]="{standalone: true}"
              placeholder="Buscar por nombre">
          </div>
        </div>

        <!-- Filtro por Status -->
        <div class="status-filter">
          <label for="statusFilter" class="sr-only">Seleccionar estado de pago</label>
          <div class="input-group" style="width: 100%;">
            <select id="statusFilter" class="form-control form-control-sm"
              (change)="onStatusChange($event.target.value)">
              <option class="low-tone-select" value="select">Seleccionar estado de pago</option>
              <option value="Abierto">Abierto</option>
              <option value="Pagado">Pagado</option>
            </select>
          </div>
        </div>
        <div>
          <!-- Desplegable para seleccionar el rango de fecha -->
          <label for="dateRange">Seleccionar Rango de Fecha:</label>
          <select id="dateRange" (change)="onDateRangeChange($event.target.value)">
            <option value="7">Últimos 7 días</option>
            <option value="14">Últimos 14 días</option>
            <option value="30">Últimos 30 días</option>
            <option value="custom">Rango personalizado</option>
          </select>
        
          <!-- Campos de fecha solo visibles si el usuario selecciona "Rango personalizado" -->
          <div *ngIf="isCustomRange">
            <label for="startDate">Fecha de Inicio:</label>
            <input type="date" id="startDate" [(ngModel)]="startDate" (change)="onDateChange()" />
        
            <label for="endDate">Fecha de Fin:</label>
            <input type="date" id="endDate" [(ngModel)]="endDate" (change)="onDateChange()" />
          </div>
        </div>
        

        <!-- Total de Ventas -->
        <div class="total-sales ml-auto text-right">
          <strong>Total de Ventas: </strong>{{ totalSales | currency }}
        </div>
      </div>

      <div style="text-align: justify;">
        <table class="table table-lg table-hover row-border hover">
          <thead class="thead-dark text-center">
            <tr>
              <th style="width: 5%;">Folio</th>
              <th style="width: 10%;">Fecha</th>
              <th style="width: 10%;">Cliente</th>
              <th style="width: 10%;">Estado de Pago</th>
              <th style="width: 10%;">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of filteredOrders" style="text-align: center;">
              <!-- Cambia orders por filteredOrders -->
              <td>{{ order.id }}</td>
              <td>{{ order.SaleOrderDate | date: 'dd/MM/yyyy' }}</td>
              <td>{{ order.Customer.firstName }} {{ order.Customer.lastName }}</td>
              <td>
                <span *ngIf="order.SaleOrderStatus === 'Abierto'">Abierto</span>
                <span *ngIf="order.SaleOrderStatus === 'Pagado'">Pagado</span>
              </td>
              <td>{{ order.TotalAmount | currency }} {{ order.CurrencyCode }}</td>
            </tr>
            <tr *ngIf="filteredOrders.length === 0"> <!-- Cambia orders por filteredOrders -->
              <td colspan="5" class="text-center">No hay órdenes disponibles</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-controls">
        <button class="btn btn-primary" (click)="onPageChange(page - 1)" [disabled]="page === 1">
          <span class="material-icons">chevron_left</span>
        </button>
        <span class="page-number">{{ page }}</span>
        <button class="btn btn-primary" (click)="onPageChange(page + 1)" [disabled]="page * limit >= totalOrders">
          <span class="material-icons">chevron_right</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->






<!-- Container-fluid starts-->
<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Manage Order</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <form>
              <div class="mb-3">
                <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText"
                  autocomplete="off" />
              </div>
            </form>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" sortable="order_id" (sort)="onSort($event)">Order Id</th>
                  <th scope="col" sortable="product" (sort)="onSort($event)">Payment</th>
                  <th scope="col" sortable="payment_status" (sort)="onSort($event)">Payment Status</th>
                  <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Payment Mehod</th>
                  <th scope="col" sortable="order_status" (sort)="onSort($event)">Order Status</th>
                  <th scope="col" sortable="date" (sort)="onSort($event)">Date</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of (tableItem$ | async)">
                  <th scope="row">{{ item.order_id }}</th>
                  <td>
                    <span *ngFor="let image of item.product">
                      <img [src]="image" class="img-30 me-2">
                    </span>
                  </td>
                  <td><span class='badge {{item.payment_class}}'>{{ item.payment_status }}</span></td>
                  <td>{{ item.paymeny_method }}</td>
                  <td><span class='badge {{item.order_class}}'>{{ item.order_status }}</span></td>
                  <td>{{item.date}}</td>
                  <td>{{item.total}}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                [pageSize]="service.pageSize">
              </ngb-pagination>
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [value]="10" [selected] ="true" [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- Container-fluid Ends -->